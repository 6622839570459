import { useToaster } from '@assets/model/Store';
import { observer } from 'mobx-react';
import { Toast, ToastContainer } from 'react-bootstrap';

export const Toaster = observer(() => {
    const toasterStore = useToaster();
    return (
        <ToastContainer
            className="p-3"
            position={'bottom-start'}
            style={{ zIndex: 1 }}
        >
            {toasterStore.messages.map((m, i) => {
                return (
                    <Toast key={m.message}>
                        <Toast.Body>{m.message}</Toast.Body>
                    </Toast>
                );
            })}
        </ToastContainer>
    );
});
