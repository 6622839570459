import { action, computed, observable, runInAction } from 'mobx'
import { OneTimeTaskModel, TaskModel, TaskState, TaskType } from './TaskModel/TaskModel'
import { getFirebase } from '@assets/firebase/FirebaseAdapater'
export class TasksStore {
	@observable
	accessor entries: TaskModel[] = []

	@observable
	accessor isLoaded: boolean = false;

	constructor() {
		this.entries = []
	}

	@computed
	get oneTimeTasks() {
		var list = this.entries.filter(task => task.type == TaskType.ONE_TIME && task.state == TaskState.OPEN);
		list.sort((a, b) => {
			var a1 = a.getSpecific<OneTimeTaskModel>();
			var b1 = b.getSpecific<OneTimeTaskModel>();

			if (a.created.getTime() == b.created.getTime()) {
				return a.name.localeCompare(b.name);
			}

			return a.created.getTime() - b.created.getTime();
		});
		return list;
	}

	@computed
	get weeklyTasks() {
		return this.entries.filter(task => task.type == TaskType.WEEKLY);
	}

	@computed
	get monthlyTasks() {
		return this.entries.filter(task => task.type == TaskType.MONTHLY);
	}

	delete(id: string) {
		getFirebase().deleteData(['tasks'], id)
	}

	getCopy(id: string) {
		let task = this.entries.find((entry) => entry.id === id)
		var js = task.toJS()

		var t = new TaskModel().fromJS(js);
		return t;
	}

	get(id: string) {
		let task = this.entries.find((entry) => entry.id === id)
		return task;
	}

	init() {
		getFirebase().listenData(['tasks'], (data) => {
			this.entries = data;
			this.isLoaded = true;
		}, TaskModel);
	}
}