.entry {
    * > .image_preview {
        width: 50%;
        max-height: 200px;
    }
}

.image_preview {
    img {
        width: 300px;
        max-height: 450px;
        object-fit: contain;
    }
}
