import { getFirebase } from '@assets/firebase/FirebaseAdapater';
import {
    useAssets,
    useLists,
    useModal,
    useTasks,
    useToaster
} from '@assets/model/Store';
import { ListContentEntry } from '@assets/model/stores/Data/Lists/ListsStore';
import {
    TaskModel,
    TaskType
} from '@assets/model/stores/Data/TaskModel/TaskModel';
import { getAI } from '@assets/openai/AIAdapter';
import { transaction } from 'mobx';
import { observer } from 'mobx-react';
import OpenAI from 'openai';
import { useEffect, useRef, useState } from 'react';
import { Button } from 'react-bootstrap';
import { Icon } from '../Icon/Icon';
import * as style from './AudioRecorder.module.less';
import { get } from 'lodash';

interface AudioRecorderProps {
    onTranscript: (transcript: string) => void;
    className?: string;
}

export const AudioRecorder = observer((props: AudioRecorderProps) => {
    const mediaStream = useRef(null);
    const chunks = useRef([]);
    const [isRecording, setIsRecording] = useState(false);

    useEffect(() => {
        if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
            console.log('getUserMedia supported.');
            navigator.mediaDevices
                .getUserMedia(
                    // constraints - only audio needed for this app
                    {
                        audio: true
                    }
                )

                // Success callback
                .then(stream => {
                    var mediaRecorder = new MediaRecorder(stream);
                    mediaStream.current = mediaRecorder;
                    mediaRecorder.ondataavailable = async e => {
                        chunks.current.push(e.data);

                        const blob = new Blob(chunks.current, {
                            type: 'audio/ogg; codecs=opus'
                        });
                        var file = new File([blob], 'speech.mp3', {
                            type: 'audio/ogg; codecs=opus'
                        });

                        const ai = getAI();

                        const res = await ai.getTranscript(file);
                        props.onTranscript(res as any as string);
                    };
                })

                // Error callback
                .catch(err => {
                    console.error(
                        `The following getUserMedia error occurred: ${err}`
                    );
                });
        } else {
            console.log('getUserMedia not supported on your browser!');
        }
    }, []);

    const iconStyle = isRecording ? style.recording : '';

    const onClick = () => {
        if (!isRecording) {
            chunks.current = [];
            (mediaStream.current as any).start();
            setIsRecording(true);
        } else {
            (mediaStream.current as any).stop();
            setIsRecording(false);
        }
    };
    const assets = useAssets();
    const iconSrc = isRecording
        ? assets.sysIcons['stop-record']
        : assets.sysIcons['record'];
    return (
        <>
            <img
                className={style.recordIcon + ' ' + props.className}
                src={iconSrc.url}
                onClick={onClick}
            ></img>
        </>
    );
});
