import { Icon } from '@assets/component/Icon/Icon';
import { useAssets, useLists } from '@assets/model/Store';
import { observer } from 'mobx-react';
import * as style from './ContentTypeFilter.module.less';
import { ContentCategory } from '@assets/model/stores/Data/Lists/ListsStore';

export const ContentTypeFilter = observer(() => {
    const icons = useAssets().contentIcons;
    const types = Object.values(icons);
    const f = useLists().filterUI;
    return (
        <div className="d-flex flex-wrap">
            {types.map(icon => {
                var cls = '';
                if (icon.name === f.contentType) {
                    cls = style.active;
                }

                return (
                    <Icon
                        key={icon.name}
                        src={icon.url}
                        className={cls}
                        onClick={() => {
                            if (f.contentType === icon.name) {
                                f.contentType = null;
                            } else {
                                f.contentType = icon.name as ContentCategory;
                            }
                        }}
                    />
                );
            })}
        </div>
    );
});
