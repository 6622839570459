.iconLarge {
    width: 64px;
    height: 64px;
    margin-bottom: 8px;
}

.iconMedium {
    width: 48px;
    height: 48px;
}

.iconSmall {
    width: 30px;
    height: 30px;
}

.iconExtraSmall {
    width: 16px;
    height: 16px;
}
.icon {
    animation: none;

    div {
        cursor: pointer;
        position: absolute;

        img {
            position: absolute;
            pointer-events: none;
        }
    }

    &:hover {
        animation: wiggle 0.5s 1 ease-in-out;
    }

    &:active {
        animation: activate_animation 1s 1 ease-in-out;
    }
}

@keyframes wiggle {
    0% {
        transform: rotate(0deg);
    }

    30% {
        transform: rotate(-5deg) scale(1.1);
    }
    60% {
        transform: rotate(5deg) scale(0.95);
    }
    90% {
        transform: rotate(0deg) scale(1);
    }
    100% {
        transform: rotate(0deg);
    }
}

@keyframes activate_animation {
    0% {
        transform: scale(1) rotate(0deg);
    }
    20% {
        transform: scale(1) rotate(3deg);
    }
    40% {
        transform: scale(1) rotate(-3deg);
    }
    50% {
        transform: scale(1.3) rotate(0deg);
    }

    70% {
        transform: scale(0.9) rotate(0deg);
    }
    100% {
        transform: scale(1) rotate(0deg);
    }
}
