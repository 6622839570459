/*
let buttons = ['Start', 'End'].map(i => {
        // let butVar = task.repeatCount == i ? 'primary' : 'secondary';
        return (
            <Button variant={'primary'} value={i}>
                {i}
            </Button>
        );
    });

	        <Form.Group className="mb-3" controlId="formHowMany">
            <ButtonGroup onClick={onClick} aria-label="Basic example">
                {buttons}
            </ButtonGroup>
        </Form.Group>
						*/

import { observer } from 'mobx-react';
import {
    Button,
    ButtonGroup,
    Dropdown,
    DropdownButton,
    Form
} from 'react-bootstrap';

export interface ButtonsView {
    value: string;
    display?: string;
}
export interface MultiButtonProps {
    selected: string;
    buttons: ButtonsView[];
    onChange: (value: string) => void;
    label: string;
}

export const MultiButton = observer((props: MultiButtonProps) => {
    let buttons = props.buttons.map(i => {
        // let butVar = task.repeatCount == i ? 'primary' : 'secondary';
        var cls = props.selected == i.value ? 'primary' : 'secondary';
        return (
            <Dropdown.Item eventKey={i.value} key={i.value}>
                {i.display ?? i.value}
            </Dropdown.Item>
        );
    });

    const onClick = (value: any) => {
        props.onChange(value);
    };

    const displayName =
        props.buttons.find(i => i.value == props.selected)?.display ??
        props.selected;
    return (
        <ButtonGroup>
            <DropdownButton
                onSelect={onClick}
                as={ButtonGroup}
                title={displayName}
                id="bg-nested-dropdown"
            >
                {buttons}
            </DropdownButton>
        </ButtonGroup>
    );
});
