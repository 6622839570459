import { action, autorun, computed, observable, values } from 'mobx';

import { getFirebase } from '@assets/firebase/FirebaseAdapater';
import { debounce } from 'lodash';
import moment from 'moment';
import { useAssets, useTasksLog } from '../../../Store';
import { ISerializeDoc, parseDate } from '../../StoreUtils';
import { TaskLogEntries } from '../TasksLogStore';
import { TaskLogModel } from './TaskLogs';

export enum TaskType {
	ONE_TIME = "ONE_TIME",
	WEEKLY = "WEEKLY",
	MONTHLY = "MONTHLY",
}

export enum TaskState {
	OPEN = "OPEN",
	COMPLETE = "COMPLETE",
}

export interface TaskLog {

}

export class OneTimeTaskModel implements ISerializeDoc<OneTimeTaskModel> {
	constructor(private owner: TaskModel) {

	}
	@observable
	accessor date: Date = new Date();

	toJS() {
		return {
			date: this.date
		}
	}

	fromJS(json: any): OneTimeTaskModel {
		this.date = parseDate(json['date']);

		return this;
	}
}

export class WeeklyTaskModel implements ISerializeDoc<WeeklyTaskModel> {
	constructor(private owner: TaskModel) {

	}

	@observable
	accessor repeatCount: number = 1;



	toJS() {

		return {
			repeatCount: this.repeatCount
		}
	}

	fromJS(json: any): WeeklyTaskModel {
		this.repeatCount = json['repeatCount'];

		return this;
	}
}

export class MonthlyTaskModel implements ISerializeDoc<MonthlyTaskModel> {
	constructor(private owner: TaskModel) {

	}
	@observable
	accessor monthly: string = 'start'

	@computed
	get isPendingTask() {
		const entries = values(this.owner.logs.entries);
		var latest: TaskLogModel = null;
		entries.forEach((entry: TaskLogModel) => {
			if (latest == null) {
				latest = entry;
			} else {
				if (entry.when > latest.when) {
					latest = entry;
				}
			}
		});

		if (latest == null) {
			return true;
		}

		var lastEntry = moment(latest.when);

		var endOfThisMonth = moment().endOf('month');
		var startOfThisMonth = moment().startOf('month');
		var b = moment();
		var daysLeftInMonth = endOfThisMonth.diff(b, 'days');


		if (daysLeftInMonth < 7) {
			//check this month 
			if (lastEntry.isAfter(startOfThisMonth)) {
				return true;
			}
		}

		var lastMonth = moment().subtract(1, 'month').startOf('month');
		if (lastEntry.isBefore(lastMonth)) {
			return true;
		}

		return false;
	}

	isThereEntryForMonth(date: Date) {

	}
	toJS() {
		return {
			monthly: this.monthly
		}
	}

	fromJS(json: any): MonthlyTaskModel {
		this.monthly = json['monthly'];
		return this;
	}
}


type TasksMap = {
	[Property in TaskType]: ISerializeDoc<any>;
}



export interface AttachmentEntry {
	url: string;
	name: string;
	type: string;
}

export enum SaveState {
	NOT_SAVED,
	SAVING,
	SAVED
}

export class TaskModel implements ISerializeDoc<TaskModel> {
	public static DbSection: string = 'tasks';
	private isAutoSaveOn: boolean = false;

	@computed
	get isSaving() {
		console.log('isSaving', this.ioState);
		return this.ioState == SaveState.SAVING;
	}


	public logs: TaskLogEntries = null;

	updateLogsObject() {
		this.logs = useTasksLog().getTaskLogs(this.id);
	}
	constructor() {
		this.id = getFirebase().generateId([TaskModel.DbSection]);
		this.updateLogsObject();
		var t = this;

		const update_document_online = debounce(async () => {
			console.log('Saving document online');
			this.ioState = SaveState.SAVING;
			await getFirebase().saveDoc([TaskModel.DbSection], this);
			this.ioState = SaveState.SAVED;
		}, 1000);

		autorun(() => {
			var toJs = this.toJS();
			var files = values(this.files);

			if (this.isAutoSaveOn) {
				update_document_online();
			}
		});
	}

	enableAutoSave() {
		this.isAutoSaveOn = true;
	}


	@observable
	accessor ioState: SaveState = SaveState.NOT_SAVED;


	@observable
	accessor state: TaskState = TaskState.OPEN;


	@action
	setTaskState(state: TaskState) {
		this.state = state;
		if (state == TaskState.COMPLETE) {
			this.logs.addEntry(new Date(), 'COMPLETE');
		} else {
			this.logs.addEntry(new Date(), 'REOPEN');
		}
	}
	//TODO: When no ID exists -> pregen ID
	getUserStoragePath(fileName: string) {
		if (this.id == "") {
			throw "Fix me"
		}

		var userId = getFirebase().userId;
		return `users/${userId}/${this.id}/${fileName}`;
	}


	@observable
	accessor created: Date = new Date();

	@observable
	accessor name: string = '';

	@observable
	accessor desc: string = '';

	@observable
	accessor icon: string = 'default';

	@observable
	accessor files: AttachmentEntry[] = [];

	@computed
	get iconUrl() {
		return useAssets().getTaskIconUrl(this.icon);
	}

	@observable
	accessor type: TaskType = TaskType.ONE_TIME;

	@observable
	accessor id: string = "";


	@observable
	accessor typeData: TasksMap = {
		[TaskType.ONE_TIME]: new OneTimeTaskModel(this),
		[TaskType.WEEKLY]: new WeeklyTaskModel(this),
		[TaskType.MONTHLY]: new MonthlyTaskModel(this)
	}

	getSpecific<T>(): T {
		return this.typeData[this.type] as T;
	}

	toJS() {
		var subData = this.typeData[this.type].toJS();

		return {
			id: this.id,
			created: this.created,
			icon: this.icon,
			name: this.name,
			desc: this.desc,
			type: this.type,
			state: this.state,
			files: this.files,
			typeData: subData
		}
	}

	fromJS(json: any) {
		this.ioState = SaveState.SAVED;
		this.isAutoSaveOn = false;

		this.name = json['name'];
		this.icon = json['icon'];
		this.type = json['type'];
		this.desc = json['desc'] ?? '';
		this.state = json['state'] ?? TaskState.OPEN;
		this.files = json['files'] ?? [];
		this.id = json['id'];

		this.updateLogsObject();

		if (json['created']) {
			this.created = parseDate(json['created']);
		} else {
			this.created = new Date();
		}

		this.typeData[this.type].fromJS(json['typeData'] ?? {});
		return this;
	}
}


//export const taskModel = new TaskModel();
//export default new TaskModel();
