import { getFirebase } from '@assets/firebase/FirebaseAdapater';
import { action, autorun, observable, onBecomeObserved, onBecomeUnobserved, values } from 'mobx';
import { ISerializeDoc, parseDate } from '../../StoreUtils';
import { TaskModel } from './TaskModel';
import { Unsubscribe } from 'firebase/auth';
import assert from 'assert';
import { useTasksLog } from '@assets/model/Store';

export class TaskLogModel implements ISerializeDoc<TaskLogModel> {
	@observable
	accessor id: string;

	@observable
	accessor when: Date;

	@observable
	accessor comment: string;

	@observable
	accessor taskId: string;

	constructor(id?: string) {
		if (id)
			this.id = id;
	}

	set(when: Date, comment: string, taskId: string) {
		this.when = when;
		this.comment = comment;
		this.taskId = taskId;
	}

	toJS() {
		return {
			comment: this.comment,
			when: this.when,
			id: this.id,
			taskId: this.taskId
		}

	}
	fromJS(json: any): TaskLogModel {
		this.comment = json['comment'];
		this.when = parseDate(json['when']);
		this.id = json['id'];
		this.taskId = json['taskId'];
		return this;
	}
}