.checkbox {
    &:hover {
        filter: drop-shadow(1px 1px 0 black) drop-shadow(-1px 1px 0 black)
            drop-shadow(1px -1px 0 black) drop-shadow(-1px -1px 0 black);
    }
    label {
        display: inline-block;
        height: 25px;
        position: relative;
        width: 40px;
        input {
            display: none;
        }

        div {
            background-color: #b85858;
            bottom: 0;
            cursor: pointer;
            left: 0;
            position: absolute;
            right: 0;
            top: 0;
            transition: 0.4s;
            border-radius: 14px;
        }

        div:before {
            background-color: #fff;
            bottom: 4px;
            content: '';
            height: 18px;
            width: 18px;
            left: 4px;
            position: absolute;
            transition: 0.4s;
            border-radius: 50%;
        }

        input:checked + div {
            background-color: #66bb6a;
        }

        input:checked + div:before {
            transform: translateX(14px);
        }
    }
}
