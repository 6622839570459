import { TranslationModel } from 'mobx-i18n';

import enUS from '../translation/en-US';

export const i18n = new TranslationModel({
	'en-US': enUS
});

export const LanguageName: Record<(typeof i18n)['currentLanguage'], string> = {
	'en-US': 'English'
};
