import { AudioRecorder } from '@assets/component/AudioRecorder/AudioRecorder';
import { UploadFile } from '@assets/component/UploadFile/UploadFile';
import { getFirebase } from '@assets/firebase/FirebaseAdapater';
import { getAI } from '@assets/openai/AIAdapter';
import { observer } from 'mobx-react';
import { useCallback, useEffect, useRef, useState } from 'react';
import Webcam from 'react-webcam';
import {
    Button,
    Card,
    Col,
    Container,
    Dropdown,
    DropdownButton,
    Form,
    InputGroup,
    Row,
    Table
} from 'react-bootstrap';
import * as style from './AIPage.module.less';
import { useLists, useToaster, useWorkbox } from '@assets/model/Store';
import {
    TaskModel,
    TaskType
} from '@assets/model/stores/Data/TaskModel/TaskModel';
import { ListContentEntry } from '@assets/model/stores/Data/Lists/ListsStore';
import { Icon } from '@assets/component/Icon/Icon';
import { set } from 'lodash';
import { ContentPreview } from './ContentPreview/ContentPreview';

export const AIPage = observer(() => {
    const fb = getFirebase();
    const [prompt, setPrompt] = useState('');
    const [textResults, setTextResults] = useState(null);
    const [actionsResult, setActionsResult] = useState(null);

    const [imageUrl, setImageUrl] = useState(null);
    const [fileObject, setFileObject] = useState(null);
    const [sendImage, setSendImage] = useState(false);
    const [selectedType, setSelectedType] = useState('A');
    const [cameraFaciingMode, setCameraFacingMode] = useState('environment');
    const videoConstraints = {
        width: 1280,
        height: 720,
        facingMode: cameraFaciingMode
    };

    const webcamRef = useRef(null);
    const capture = useCallback(async () => {
        const imageSrc = webcamRef.current.getScreenshot();
        setImageUrl(imageSrc);

        const res: Response = await fetch(imageSrc);
        const blob: Blob = await res.blob();
        setFileObject(new File([blob], 'capture', { type: 'image/jpeg' }));
        setSendImage(true);
    }, [webcamRef, cameraFaciingMode]);

    useEffect(() => {
        const workbox = useWorkbox();
        const fetchSharedFile = async () => {
            const data = await workbox.messageSW({
                type: 'GET_FILES_SHARED'
            });

            if (!data || !data.files) return;

            for (var i = 0; i < data.files.length; i++) {
                const f = data.files[i];
                const t = f.type;
                if (t.startsWith('image')) {
                    onFileAdded(f);
                } else if (t.startsWith('text')) {
                    const reader = new FileReader();
                    reader.onload = function () {
                        setPrompt(reader.result as string);
                    };

                    reader.readAsText(f);
                }
            }

            workbox.messageSW({
                type: 'CLEAR_FILES_SHARED'
            });
        };

        fetchSharedFile();
    }, []);

    const onFileAdded = async file => {
        setFileObject(file);
        setImageUrl(URL.createObjectURL(file));
        setSendImage(true);
    };

    useEffect(() => {
        const onPaste = async e => {
            try {
                if (e.clipboardData.files.length == 0) return;

                onFileAdded(e.clipboardData.files[0]);
            } catch (e) {
                console.log(e);
            }
        };

        window.addEventListener('paste', onPaste);

        return () => {
            window.removeEventListener('paste', onPaste);
        };
    }, []);

    const sendTextPrompt = async () => {
        const ai = getAI();
        var result = null;
        const fo = sendImage ? fileObject : null;
        switch (selectedType) {
            case 'Q':
                result = await ai.sendToOpenAI_Question(prompt, fo);
                break;
            case 'A':
                result = await ai.sendToOpenAI_GetAction(prompt, fo);
                break;
        }

        if (result) {
            if (selectedType == 'A') {
                setActionsResult(result);
            } else {
                setTextResults(JSON.stringify(result));
            }
        }
    };

    const processAction = async command => {
        const toaster = useToaster();
        switch (command.action) {
            case 'NewTask':
                var newTask = new TaskModel();
                newTask.type = TaskType.ONE_TIME;
                newTask.name = command.name;
                newTask.desc = command.description;

                await getFirebase().saveDoc([TaskModel.DbSection], newTask);
                toaster.addMessage(`> Task: ${command.name}`);
                break;

            case 'NewContent':
                const lists = useLists();
                const contentId = lists.categories.find(
                    c => c.name == command.contentType
                );
                if (!contentId) {
                    alert('Invalid content type');
                    return;
                }
                var content = new ListContentEntry();
                content.name = command.name;
                content.categoryId = contentId.id;
                toaster.addMessage(
                    `> Content Added: ${command.name} / ${command.contentType}`
                );
                content.save();

                break;
        }
    };

    const onTranscript = (transcript: string) => {
        setPrompt(transcript);
    };

    return (
        <>
            <Card>
                <Card.Body>
                    <Container className="p-0">
                        <Row className="pb-3">
                            <Col xs={12}>
                                <Form.Control
                                    className={style.promptInput}
                                    as="textarea"
                                    rows={4}
                                    wrap="hard"
                                    placeholder="Prompt?"
                                    value={prompt}
                                    onChange={e => setPrompt(e.target.value)}
                                />

                                <AudioRecorder
                                    className={style.recorder}
                                    onTranscript={onTranscript}
                                />
                            </Col>
                        </Row>
                        <Row className="pb-3 justify-content-between">
                            <Col xs={2}>
                                <UploadFile
                                    className={style.uploadFile}
                                    onNewFile={onFileAdded}
                                ></UploadFile>
                            </Col>
                            <Col xs={2}>
                                <Webcam
                                    audio={false}
                                    height={720}
                                    ref={webcamRef}
                                    screenshotFormat="image/jpeg"
                                    width={1280}
                                    videoConstraints={videoConstraints}
                                />
                                <Icon iconSys="camera" onClick={capture}></Icon>
                            </Col>

                            <Col xs={3} className="flex-fill"></Col>
                            <Col xs={5}>
                                <div className={style.typeDropdown}>
                                    <InputGroup>
                                        <DropdownButton
                                            variant="outline-secondary"
                                            title={
                                                selectedType == 'Q'
                                                    ? 'Question'
                                                    : 'Action'
                                            }
                                            onSelect={e => {
                                                setSelectedType(e);
                                            }}
                                        >
                                            <Dropdown.Item eventKey="Q">
                                                Question
                                            </Dropdown.Item>
                                            <Dropdown.Item eventKey="A">
                                                Action
                                            </Dropdown.Item>
                                        </DropdownButton>

                                        <Button
                                            onClick={() => {
                                                sendTextPrompt();
                                            }}
                                        >
                                            Send
                                        </Button>
                                    </InputGroup>
                                </div>
                            </Col>
                        </Row>
                        {actionsResult && (
                            <Row>
                                <Col>
                                    <Container>
                                        <Row>
                                            <Col xs={12}>
                                                <Table
                                                    striped
                                                    bordered
                                                    hover
                                                    size="sm"
                                                >
                                                    <tbody>
                                                        {actionsResult.actions.map(
                                                            e => (
                                                                <ContentPreview
                                                                    key={e.name}
                                                                    name={
                                                                        e.name
                                                                    }
                                                                    contentType={
                                                                        e.contentType
                                                                    }
                                                                />
                                                            )
                                                        )}
                                                    </tbody>
                                                </Table>
                                            </Col>
                                        </Row>
                                    </Container>
                                </Col>
                            </Row>
                        )}
                        {imageUrl && (
                            <>
                                <Row className="pb-3">
                                    <Col>
                                        <div className={style.imageContainer}>
                                            <img
                                                id="preview_img"
                                                src={imageUrl}
                                                alt="your image"
                                                className={style.imagePreview}
                                            />
                                            <Form.Check // prettier-ignore
                                                type="switch"
                                                id="imageSwitch"
                                                className={style.imageSwitch}
                                                checked={
                                                    fileObject && sendImage
                                                }
                                                onChange={e =>
                                                    setSendImage(
                                                        e.target.checked
                                                    )
                                                }
                                            />
                                        </div>
                                    </Col>
                                </Row>
                            </>
                        )}
                        <Row className="pb-3">
                            <Col>{textResults}</Col>
                        </Row>
                    </Container>
                </Card.Body>
            </Card>
            <canvas id="canvas"> </canvas>
        </>
    );
});
