import { useLists, useModal } from '@assets/model/Store';
import { ListContentEntry } from '@assets/model/stores/Data/Lists/ListsStore';
import OpenAI from 'openai';
import { z } from "zod";
import { zodResponseFormat } from "openai/helpers/zod";
import { UIModalStore } from '@assets/model/stores/UI/UIModalStore';

async function getBase64(file) {
	return new Promise((resolve, reject) => {
		var reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = function () {
			resolve(reader.result);
		};
		reader.onerror = function (error) {
			console.log('Error: ', error);
			resolve(reader.error);
		};
	});
}

class AIAdapter {
	openai: OpenAI;
	constructor() {
		this.openai = new OpenAI({
			apiKey: 'sk-cyyrXD97gTbtJNon8wyArslj9T2EhlAxv0vJED_wONT3BlbkFJnrZAlUOvr7wsOAM0R8ycdJLsaV6aoYRZNXs_drst4A',
			dangerouslyAllowBrowser: true
		});
	}

	async generateNewListCategory(categoryName) {
		const openai = this.openai;
		const completion = await openai.chat.completions.create({
			model: 'gpt-4o',
			messages: [
				{
					role: 'system',
					content:
						'You are a helpful assistant. I will send you a category name of some type of content. Might be movies or books or something else. Please return a JSON array of upto 20 of the most common categories of that type of content. '
				},
				{
					role: 'user',
					content: [
						{
							type: 'text',
							text: `The type of content I am intrested in is ${categoryName}. Please return a JSON array of upto 20 of the most common categories of that type of content. Example JSON: ["Action", "Comedy", "Drama", ...]`
						}
					]
				}
			],
			max_tokens: 1000
		});

		var content = completion.choices[0].message.content;
		var js = content.replace('```json\n', '').replace('```', '');
		var json = JSON.parse(js);
		return json;
	};

	async getTranscript(file) {
		const modal = useModal();
		modal.shouldBlockUI = true;

		const openai = new OpenAI({
			apiKey: 'sk-cyyrXD97gTbtJNon8wyArslj9T2EhlAxv0vJED_wONT3BlbkFJnrZAlUOvr7wsOAM0R8ycdJLsaV6aoYRZNXs_drst4A',
			dangerouslyAllowBrowser: true
		});

		const transcription = await openai.audio.transcriptions.create({
			file: file,
			model: 'whisper-1',
			response_format: 'text'
		});

		modal.shouldBlockUI = false;
		return transcription;
	};

	async sendToOpenAI_Question(text: string = null, fileObject: any = null) {
		const modal = useModal();
		modal.shouldBlockUI = true;

		const openai = new OpenAI({
			apiKey: 'sk-cyyrXD97gTbtJNon8wyArslj9T2EhlAxv0vJED_wONT3BlbkFJnrZAlUOvr7wsOAM0R8ycdJLsaV6aoYRZNXs_drst4A',
			dangerouslyAllowBrowser: true
		});

		const content = [];

		if (fileObject) {
			const imageAsBase64 = await getBase64(fileObject);
			content.push({
				type: 'image_url',
				image_url: {
					url: imageAsBase64
				}
			});
		}

		if (text) {
			const pre_question = "This is my question: ";
			content.push({
				type: 'text',
				text: pre_question + text
			});
		}

		const completion = await openai.chat.completions.create({
			model: 'gpt-4o-2024-08-06',
			messages: [
				{
					role: 'system',
					content:
						'You are a helpful assistant. I will send you a question as text and maybe an attached image.  Please try to answer the question. Textual response. Not too long. Upto 3-5 sentances.'
				},
				{
					role: 'user',
					content: content
				}
			],
			max_tokens: 1000
		});

		const msg = completion.choices[0].message;
		if (msg.refusal) {
			console.log('Open AI: Refusal:', msg.refusal);
			return null;
		}
		modal.shouldBlockUI = false;

		return msg.content;
	};

	async sendToOpenAI_GetAction(text: string = null, fileObject: any = null) {
		const modal = useModal();
		modal.shouldBlockUI = true;

		const openai = new OpenAI({
			apiKey: 'sk-cyyrXD97gTbtJNon8wyArslj9T2EhlAxv0vJED_wONT3BlbkFJnrZAlUOvr7wsOAM0R8ycdJLsaV6aoYRZNXs_drst4A',
			dangerouslyAllowBrowser: true
		});

		const prompt = `There is 1 type of action. Add 'NewContent' - There are the types of content: "Movies", "TVShows", "PCGames", "Books", "Music", "FoodReceipes".
					    Based on the following transcript figure out what type of task this is. Please reply in JSON format.						
						- NewContent will have a name specifying the content and contentType. Example response: {action: 'NewContent', name: '{Name of content in transcript}', contentType: '{Type of content in transcript}', description: 'Description of the content from transcript'}.
						
						If the specific content is part of a franchise include the others as actions.

						Please use their full names, the most common ones used.
						
						If an image is included it is most likely a screenshot of a phone. In it there is content I am intrested in. It can be a movie title, book, author, music.
						Please reply in JSON format. No extra text!`;
		``
		const lists = useLists();
		const categories = lists.categories.map(c => c.name);
		const oneAction = z.object({
			action: z.enum(["NewTask", "NewContent"]),
			name: z.string(),
			description: z.string().optional(),
			contentType: z.enum(["Movies", "TVShows", "PCGames", "Books", "Music", "FoodReceipes", "None"])
		});

		const listOfActions = z.object({
			actions: z.array(oneAction)
		})

		const content: any[] = [{
			type: 'text',
			text: prompt
		}];

		if (fileObject) {
			const imageAsBase64 = await getBase64(fileObject);
			content.push({
				type: 'image_url',
				image_url: {
					url: imageAsBase64
				}
			});
		}

		if (text) {
			const pre_question = "Extra info: ";
			content.push({
				type: 'text',
				text: pre_question + text
			});
		}

		const completion = await openai.chat.completions.create({
			model: 'gpt-4o-2024-08-06',
			messages: [
				{
					role: 'system',
					content:
						'You are a helpful assistant. I will send you a text voice transcript of a request of mine. Please return what you infer from it based on the available actions I specify I can take. Reply in JSON. No extra text.'
				},
				{
					role: 'user',
					content: content
				}
			],
			response_format: zodResponseFormat(listOfActions, "actions_schema"),
			max_tokens: 400
		});

		const msg = completion.choices[0].message;
		if (msg.refusal) {
			console.log('Open AI: Refusal:', msg.refusal);
			return null;
		}
		modal.shouldBlockUI = false;
		return JSON.parse(msg.content);
	};
}


const mAI = new AIAdapter();
export function getAI() {
	return mAI;
}
