import { Firestore } from 'firebase/firestore';
import { TasksStore } from './stores/Data/TasksStore';
import { getFirebase } from 'src/firebase/FirebaseAdapater';
import { UIModalStore } from './stores/UI/UIModalStore';
import { AssetsStore } from './stores/UI/AssetsStore';
import { computed, observable, values } from 'mobx';
import { TaskType } from './stores/Data/TaskModel/TaskModel';
import { UIToasterStore } from './stores/UI/UIToasterStore';
import { TasksLogStore } from './stores/Data/TasksLogStore';
import { Workbox } from 'workbox-window';
import { CacheStore } from './stores/CacheStore';
import { ListsStore } from './stores/Data/Lists/ListsStore';
import { AnimationStore } from './stores/UI/AnimationStore';
import { UIStore } from './stores/UI/UIStore';
export enum LoadState {
    NOT_LOADED = 'NOT_LOADED',
    LOADING = 'LOADING',
    LOADED = 'LOADED'
}

class Store {
    tasks: TasksStore = new TasksStore();
    lists: ListsStore = new ListsStore();
    modal: UIModalStore = new UIModalStore();
    animation: AnimationStore = new AnimationStore();
    toaster: UIToasterStore = new UIToasterStore();
    assets: AssetsStore = new AssetsStore();
    tasksLog: TasksLogStore = new TasksLogStore();
    cache: CacheStore = new CacheStore();
    ui: UIStore = new UIStore();

    db: Firestore = null;

    constructor() {}

    workbox: Workbox;

    didInit: boolean = false;

    @computed
    get isLoaded() {
        return this.tasks.isLoaded;
    }

    init(userId: string) {
        if (this.didInit) return;

        this.didInit = true;

        console.log('User Id: ', userId);

        this.db = getFirebase().db;
        this.tasks.init();
        this.tasksLog.init();
        this.lists.init();
    }
}

const store = new Store();

export const useStore = () => store;
export const useUI = () => store.ui;
export const useAssets = () => store.assets;
export const useModal = () => store.modal;
export const useTasks = () => store.tasks;
export const useToaster = () => store.toaster;
export const useTasksLog = () => store.tasksLog;
export const useWorkbox = () => store.workbox;
export const useCache = () => store.cache;
export const useLists = () => store.lists;
export const useAnimations = () => store.animation;

export default store;
