import { Icon } from '@assets/component/Icon/Icon';
import { useAssets, useLists, useModal } from '@assets/model/Store';
import {
    ListCategoryModel,
    ListContentEntry
} from '@assets/model/stores/Data/Lists/ListsStore';
import { useNavUtils } from '@assets/utils/NavUtils';
import { values } from 'mobx';
import { observer } from 'mobx-react';
import OpenAI from 'openai';
import { useEffect, useRef } from 'react';
import { Card } from 'react-bootstrap';
import { ListEntry } from './ListEntry/ListEntry';
import * as style from './ListsPage.module.less';
const { Dropzone } = require('dropzone');

export const ListsPage = observer(() => {
    const lists = useLists();
    const dropzoneRef = useRef();
    const imagePrev = useRef();
    const assets = useAssets();
    const nav = useNavUtils();
    const modal = useModal();

    useEffect(() => {
        lists.filterUI.reset();
    }, []);

    const addCatgeory = async () => {
        var listName = prompt('Enter a new type of category');
        if (!listName) return;

        var list = new ListCategoryModel();
        list.name = listName;

        const categories: string[] = await sendToOpenAI_NewCategory(listName);
        list.categories = categories;
        list.save();
    };

    const sendToOpenAI_NewCategory = async categoryName => {
        const openai = new OpenAI({
            apiKey: 'sk-cyyrXD97gTbtJNon8wyArslj9T2EhlAxv0vJED_wONT3BlbkFJnrZAlUOvr7wsOAM0R8ycdJLsaV6aoYRZNXs_drst4A',
            dangerouslyAllowBrowser: true
        });

        const completion = await openai.chat.completions.create({
            model: 'gpt-4o-mini',
            messages: [
                {
                    role: 'system',
                    content:
                        'You are a helpful assistant. I will send you a category name of some type of content. Might be movies or books or something else. Please return a JSON array of upto 20 of the most common categories of that type of content. '
                },
                {
                    role: 'user',
                    content: [
                        {
                            type: 'text',
                            text: `The type of content I am intrested in is ${categoryName}. Please return a JSON array of upto 20 of the most common categories of that type of content. Example JSON: ["Action", "Comedy", "Drama", ...]`
                        }
                    ]
                }
            ],
            max_tokens: 1000
        });

        var content = completion.choices[0].message.content;
        var js = content.replace('```json\n', '').replace('```', '');
        var json = JSON.parse(js);
        return json;
    };

    var items = [];
    const copy = values(lists.contentList) as ListContentEntry[];
    var sorted = copy
        .slice()
        .sort((a, b) => b.created.getTime() - a.created.getTime());

    const filter = lists.filterUI;
    sorted.forEach(i => {
        if (filter.rating > -1 && i.user_rating != filter.rating) {
            return;
        }

        if (filter.contentType != null && i.category != filter.contentType) {
            return;
        }

        if (filter.search != '') {
            if (
                i.name.toLowerCase().indexOf(filter.search.toLowerCase()) == -1
            ) {
                return;
            }
        }

        if (filter.show_only_not_rated && i.user_rating != -1) {
            return;
        }

        if (items.length < 20) {
            items.push(<ListEntry key={i.id} entry={i} />);
        }
    });

    return (
        <>
            <Icon
                iconSys="menu"
                size="M"
                className={style.filterButton}
                onClick={() => {
                    modal.openContentFilter();
                }}
            />

            <Card>
                <Card.Body className="p-1 pt-3">
                    <div className="d-flex w-100 flex-wrap">
                        <div className={style.contentItemsContainer}>
                            {items}
                        </div>
                    </div>
                </Card.Body>
            </Card>

            {/* <Button onClick={() => addCatgeory()}>Add Category</Button> */}
        </>
    );
});
