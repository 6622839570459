import { Timestamp } from 'firebase/firestore';

export const parseDate = (date: any) => {
	if (date instanceof Date) {
		return date;
	} else if (date instanceof Timestamp) {
		return date.toDate();
	} else {
		if (date.hasOwnProperty('seconds')) {
			return Timestamp.fromMillis(date.seconds * 1000).toDate();
		}
	}
}
export interface ISerializeDoc<T> {
	toJS(): any;
	fromJS(json: any): T;
}
