import { i18n } from './model/Translation';

const { t } = i18n;

export const mainNavLinks = () => [
	{ href: '#/', title: t('home_page') },
	{ href: '#/task/new', title: t('addtask') },
	{ href: '#/tasks', title: t('tasks') },
	{ href: '#/lists', title: t('lists') },
	{ href: '#/ai', title: t('ai') },
	{ href: '#/icons', title: t('icons') },
];
