import { HashRouter, Route, Routes } from 'react-router-dom';

import { LoadingModal } from '@assets/component/LoadingModal/LoadingModal';
import { Toaster } from '@assets/component/Toaster';
import ModalContainer from '@assets/modals/ModalContainer';
import { useStore } from '@assets/model/Store';
import { observer } from 'mobx-react';
import { MainNavigator } from '../component/MainNavigator';
import { AIPage } from './AI/AIPage';
import HomePage from './Home';
import { IconsPage } from './Icons';
import { ListsContent } from './Lists/ListsContent';
import { ListsPage } from './Lists/ListsPage';
import { EditTaskPage } from './Task/EditTaskPage';
import { TasksListPage } from './TasksList';
import AnimationLayer from '@assets/component/AnimLayer/AnimationsLayer';

export const PageFrame = observer(() => {
    const store = useStore();
    if (!store.isLoaded) {
        return <div>Loading..</div>;
    }

    return (
        <>
            <LoadingModal />
            <MainNavigator />
            <ModalContainer />
            <Toaster />
            <AnimationLayer />
            <HashRouter>
                <Routes>
                    <Route path="/" element={<HomePage />} />
                    <Route path="/task/:id" element={<EditTaskPage />} />
                    <Route path="/tasks" element={<TasksListPage />} />
                    {<Route path="/lists" element={<ListsPage />} />}
                    {<Route path="/content/:id" element={<ListsContent />} />}
                    {<Route path="/ai" element={<AIPage />} />}
                    {<Route path="/icons" element={<IconsPage />} />}
                </Routes>
            </HashRouter>
        </>
    );
});
