import { NavigateFunction, useNavigate } from 'react-router-dom';

export class NavUtils {
	constructor(private nav: NavigateFunction) {
	}

	toLists() {
		this.nav(`/lists`);
	}
	toContent(contentId: string) {
		this.nav(`/content/${contentId}`);
	}

	toEditTask(taskId: string) {
		this.nav(`/task/${taskId}`);
	}

	toViewTask(taskId: string) {
		this.nav(`/task/${taskId}`);
	}

	back() {
		this.nav(-1);
	}
}


export function useNavUtils() {
	const nav = useNavigate();
	return new NavUtils(nav);
}