.pdf {
    width: 100%;
    height: 600px;
}

.txt {
    width: 100%;
    height: 600px;
}

.img {
    width: 100%;
    height: 100%;
}
