import { observer } from 'mobx-react';
import { Button, ButtonGroup, Form } from 'react-bootstrap';
import { ButtonsView, MultiButton } from 'src/component/FormGroup/MultiButton';
import {
    WeeklyTaskModel,
    TaskModel
} from '@assets/model/stores/Data/TaskModel/TaskModel';

export const WeeklyTask = observer((props: { task: TaskModel }) => {
    const { task } = props;

    let buttons: ButtonsView[] = [
        { value: '1' },
        { value: '2' },
        { value: '3' }
    ];

    const specific = task.getSpecific<WeeklyTaskModel>();

    const onClick = (e: string) => {
        specific.repeatCount = parseInt(e);
    };

    return (
        <div></div>
        // <MultiButton
        //     selected={specific.repeatCount.toString()}
        //     buttons={buttons}
        //     onChange={onClick}
        //     label="Repeat #"
        // />
    );
});
