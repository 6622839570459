import { observer } from 'mobx-react';
import { Icon } from '../Icon/Icon';
import * as style from './StarRating.module.less';
import { useState } from 'react';

interface StarRatingProps {
    rating: number;
    onChange: (rating: number) => void;
}

export const StarRating = observer((props: StarRatingProps) => {
    const { rating, onChange } = props;
    const stars = [];
    for (let i = 1; i <= 5; i++) {
        const isOn = i <= rating;
        var className = isOn ? style.starOn : style.starOff;
        var classes = [style.star, className].join(' ');
        stars.push(
            <Icon
                className={classes}
                iconSys="star"
                key={i}
                onClick={() => {
                    props.onChange(i);
                }}
                onHold={() => {
                    props.onChange(-1);
                }}
            ></Icon>
        );
    }
    return <div className={style.container}>{stars}</div>;
});
