.weeklyIconCell {
    width: calc(100% * (1 / 4));
}

.activeDay {
    font-weight: 600;
}

.dayLabel {
    cursor: pointer;
}
