import Path from '@joemaddalone/path';
import { useCallback, useEffect, useState } from 'react';
import * as style './AnimationLayer.module.less';
import paper_plane from './plane.svg';
import { observer } from 'mobx-react';
import { useAnimations, useAssets, useStore } from '@assets/model/Store';
import { Point } from '@assets/model/stores/UI/AnimationStore';
import { Icon } from '../Icon/Icon';

var globalId = 0;

interface AnimationLayerProps {}
const AnimationLayer = observer((props: AnimationLayerProps) => {	
	const anim = useAnimations();

	useEffect(() => {
		if (anim.animation) { 
			addAnimation(anim.animation.from, anim.animation.to, anim.animation.icon);
		}
		anim.animation = null;
	}, [anim.animation]);
	
	
    //const [animations, setAnimations] = useState<{ [id: string]: ReactNode }>({});
    const [animations, setAnimations] = useState<any>([]);

    const setAnimFromOutside = useCallback((fn: any) => {
        setAnimations(fn);
    }, []);

    const addAnimation = (fromPos: Point, toPos: Point, icon:string) => {        

		const id = 'id_' + globalId.toString();
		globalId++;
		

        const a = fromPos.x - toPos.x;
		const b = fromPos.y - toPos.y;
		
		var dist = Math.sqrt(a * a + b * b) / 160;
		dist = Math.max(dist, 2.0);
		dist = Math.min(dist, 2.5);
		// if (dist < 2.2) {
		// 	dist = 2.2;
		// }
        const time = (dist.toFixed(2)).toString() + 's';
		
        //console.log('ANIM TIME:' + time);

		const planeContainer = [style.PlaneContainer].join(' ');
		const planeScaleAnim = [style.PlaneScaleAnim].join(' ');
		const assets = useAssets();
		const iconPath = assets.taskIcons[icon].url;
        const anim = (
            <div
                className={planeContainer}
                key={id}
                style={{
                    offsetPath: PathMaker(fromPos, toPos),
                    animationDuration: time
                }}
			>				
                {/* <img
                    src={paper_plane}
                    className={planeScaleAnim}
                    style={{
                        animationDuration: time,
                        position: 'absolute',
                        width: '20px',
                        height: '20px'
                    }}
				/>
				 */}
				<img
                    src={iconPath}
                    className={planeScaleAnim}
                    style={{
                        animationDuration: time,
                        position: 'absolute',
                        width: '32px',
                        height: '32px'
                    }}
                />
            </div>
        );

        setAnimFromOutside((arr: any) => {
            const copy = [...arr];
            copy.push(anim);
            return copy;
        });
        console.log('ADD ANIM');
        setTimeout(() => {
            setAnimFromOutside((a: any) => {
                const r = a.filter((anim: any) => anim.key != id);
                return r;
            });
        }, 5000);
    };    

    interface pt {
        x: number;
        y: number;
    }

    const PMaker2 = (from: pt, to: pt) => {
        const path = new Path().M(from.x, from.y).L(to.x, to.y);
        const ret = "path('" + path.toString() + "')";
        return ret;
    };
    const PathMaker = (from: pt, to: pt) => {
        const mpx = (to.x + from.x) * 0.5;
		const mpy = (to.y + from.y) * 0.5;
		

        const theta = Math.atan2(to.y - from.y, to.x - from.x) - Math.PI / 2;

        //const range = 30;
        const offset = (Math.random() * 140 - 70) * 2;
        const c1x = mpx + offset * Math.cos(theta);
        const c1y = mpy + offset * Math.sin(theta);

        const path = new Path()
            .M(from.x, from.y)
            .C(from.x, from.y, c1x, c1y, to.x, to.y);
        const ret = "path('" + path.toString() + "')";
        //console.log(ret);
        //return "path('M 239 17 C 142 17 48.5 103 48.5 213.5 C 48.5 324 126 408 244 408 C 362 408 412 319 412 213.5 C 412 108 334 68.5 244 68.5 C 154 68.5 102.68 135.079 99 213.5 C 95.32 291.921 157 350 231 345.5 C 305 341 357.5 290 357.5 219.5 C 357.5 149 314 121 244 121 C 174 121 151.5 167 151.5 213.5 C 151.5 260 176 286.5 224.5 286.5 C 273 286.5 296.5 253 296.5 218.5 C 296.5 184 270 177 244 177 C 218 177 197 198 197 218.5 C 197 239 206 250.5 225.5 250.5 C 245 250.5 253 242 253 218.5')"
        //return "path('M20,20 C20,100 200,0 200,100')"
        return ret;
    };

	const animLayer = [style.AnimationLayer].join(' ');
    return <div className={animLayer}>{animations}</div>;
});

export default AnimationLayer;
