.container {
    display: flex;
    width: 200px;
}

.delaysIn(@counter) when (@counter > 0) {
    .delaysIn((@counter - 1));
    .starOn::nth-child(@{counter}) {
        transition-delay: calc(0.03s * @counter);
    }
}

.delaysOut(@counter) when (@counter > 0) {
    .delaysOut((@counter - 1));
    .starOff::nth-child(@{counter}) {
        transition-delay: calc(0.03s * 5 - @counter * 0.03s);
    }
}

.star {
    opacity: 0;
    transition: opacity 0.25s;
}
.starOff {
    opacity: 0.25;
}

.delaysOut(5);

.starOn {
    opacity: 1;
}

.delaysIn(5);
