import { observer } from 'mobx-react';
import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useAssets, useModal, useStore } from 'src/model/Store';
import * as style from './IconSelectionModal.module.less';
import { Icon } from '@assets/component/Icon/Icon';

export const IconSeletionModal = observer(() => {
    const modalStore = useModal();
    const icons = useAssets().taskIcons;
    var iconKeys = Object.keys(icons).sort();

    return (
        <>
            <div className={style.icons_container}>
                {iconKeys.map(key => {
                    var icon = icons[key];
                    return (
                        <Icon
                            key={icon.name}
                            iconTask={icon.name}
                            size="L"
                            onClick={() => {
                                modalStore.reportIconSelected(icon.name);
                            }}
                        />
                    );
                })}
            </div>
        </>
    );
});

export default IconSeletionModal;
