import { observable } from 'mobx'

export type ModalType = 'None' | 'IconSelect' | 'ViewAttachment' | 'ModalFilter';
export class UIModalStore {

	onSelectIcon: (icon: string) => void;
	openSelectIcon(onSelect: (icon: string) => void) {
		this.onSelectIcon = onSelect;
		this.modalTitle = 'Icon Selection';
		this.activeModal = 'IconSelect';
	}

	openContentFilter() {
		this.modalTitle = 'Filter';
		this.activeModal = 'ModalFilter';
	}
	closeModal() {
		this.activeModal = 'None';
	}

	reportIconSelected(icon: string) {
		if (icon != "") {
			this.onSelectIcon(icon);
		}
		this.closeModal();
	}

	openViewAttachment(url: string) {
		this.activeModal = 'ViewAttachment';
		this.modalTitle = 'Attachment';
		this.attachmentUrl = url;
	}

	@observable
	accessor activeModal: ModalType = 'None'

	@observable
	accessor modalTitle: string;

	@observable
	accessor attachmentUrl: string;


	@observable
	accessor shouldBlockUI: boolean = false;
}