import { computed, observable, values } from 'mobx'
import { OneTimeTaskModel, TaskModel, TaskState, TaskType } from './TaskModel/TaskModel'
import { getFirebase } from '@assets/firebase/FirebaseAdapater'
import { ISerializeDoc } from '../StoreUtils';
import { TaskLogModel } from './TaskModel/TaskLogs';
import { Interface } from 'readline/promises';
import moment from 'moment';
import { useTasks } from '@assets/model/Store';
import { assert } from 'console';

interface DaySummary {
	date: Date;
	list: TaskLogModel[];
}

export class TaskLogEntries {
	private global_path: string[] = ['logs'];

	constructor(public readonly taskId: string) {
	}

	@observable
	accessor entries: TaskLogModel[] = []

	addEntry(when: Date, comment: string) {
		var newId = getFirebase().generateId(this.global_path);
		var e: TaskLogModel = new TaskLogModel(newId);
		e.set(when, comment, this.taskId);

		getFirebase().saveDoc(this.global_path, e);
	}


	removeEntry(id: string) {
		getFirebase().deleteData(this.global_path, id);
	}
}
export class TasksLogStore {

	@observable
	accessor entries: TaskLogModel[] = []

	logInstances: { [taskId: string]: TaskLogEntries } = {}

	getTaskLogs(taskId: string) {
		if (!this.logInstances[taskId]) {
			this.logInstances[taskId] = new TaskLogEntries(taskId);
		}
		return this.logInstances[taskId];
	}

	@computed
	get weeklyStats() {
		const l = values(this.entries);
		var weekly: DaySummary[] = [];
		const tasks = useTasks();
		for (var i = 0; i < 3; i++) {
			const oneTimeList: { [taskId: string]: TaskLogModel } = {};
			var current = moment(new Date());
			var d = current.subtract(i, 'days');
			var on_the_day = this.entries.filter((entry) => {
				var task = tasks.get(entry.taskId);
				var sameDay = d.isSame(moment(entry.when), 'day');

				if (sameDay && task) {
					if (task.type == TaskType.ONE_TIME) {
						oneTimeList[entry.taskId] = entry;
						return false;
					}
				}
				return sameDay;
			});

			for (var oneTimeEntry in oneTimeList) {
				if (tasks.get(oneTimeEntry).state == TaskState.COMPLETE) {
					on_the_day.push(oneTimeList[oneTimeEntry]);
				}
			}

			weekly.push({ date: d.toDate(), list: on_the_day });
		}

		return weekly.reverse();
	}

	@computed get todoTasks() {
		return "";
	}

	init() {
		getFirebase().listenData(['logs'], (data) => {
			if (this.entries.length > data.length) {
				this.entries = [];
				for (let key in this.logInstances) {
					this.logInstances[key].entries = [];
				}
			}

			const newEntries = data.filter((e) => { return this.entries.findIndex((e2) => e2.id == e.id) == -1 });

			this.entries = data;
			for (let entry of newEntries) {
				var instance = this.getTaskLogs(entry.taskId);
				instance.entries.push(entry);
			}
		}, TaskLogModel);
	}
}