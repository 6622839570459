import { useAssets } from '@assets/model/Store';
import { SysIcons } from '@assets/model/stores/UI/AssetsStore';
import { observer } from 'mobx-react';
import { useRef } from 'react';
import * as style from './Icon.module.less';
import { PoistionTracker } from '../AnimLayer/PositionTracker/PositionTracker';
import {
    Point,
    PositionCategory
} from '@assets/model/stores/UI/AnimationStore';

type IconSize = 'XS' | 'S' | 'M' | 'L';

interface IconProps {
    iconSys?: SysIcons;
    iconTask?: string;
    src?: string;

    className?: string;
    size?: IconSize;
    onClick?: () => void;
    onHold?: () => void;
    id?: string;
    tracker?: PositionCategory;
}

export const Icon = observer((props: IconProps) => {
    const clickTimer = useRef(null);
    const clickStartTime = useRef(0);
    const id = props.id || '';

    const className = props.className || '';
    const icons = useAssets();
    const size: IconSize = props.size || 'M';

    const sizeClass = {
        XS: style.iconExtraSmall,
        S: style.iconSmall,
        M: style.iconMedium,
        L: style.iconLarge
    };

    var iconSrc = null;
    if (props.iconSys) {
        iconSrc = icons.sysIcons[props.iconSys].url;
    } else if (props.iconTask) {
        iconSrc = icons.taskIcons[props.iconTask].url;
    } else if (props.src) {
        iconSrc = props.src;
    }
    const icon = props.iconSys;

    const clearTimer = () => {
        if (clickTimer.current) {
            clearTimeout(clickTimer.current);
            clickTimer.current = null;
        }
    };
    const onMouseUp = e => {
        e.preventDefault();

        const clickEndTime = new Date().getTime();
        if (clickEndTime - clickStartTime.current < 200) {
            clickStartTime.current = 0;
            if (props.onClick) props.onClick();
        }

        clearTimer();
    };

    const onMouseDown = e => {
        // if (e.button !== 0) {
        //     return;
        // }

        e.preventDefault();

        clickStartTime.current = new Date().getTime();
        clearTimer();
        clickTimer.current = setTimeout(() => {
            if (props.onHold) props.onHold();
        }, 750);
    };
    return (
        <div
            id={id}
            className={
                style.icon +
                ' ' +
                sizeClass[size] +
                ' ' +
                className +
                ' d-flex justify-content-center align-items-center'
            }
            onMouseDown={onMouseDown}
            onMouseUp={onMouseUp}
            onMouseLeave={onMouseUp}
            onTouchStart={onMouseDown}
            onTouchEnd={onMouseUp}
        >
            <img className={sizeClass[size]} src={iconSrc}></img>

            {props.tracker && <PoistionTracker id={id} type="weekly" />}
        </div>
    );
});
