import { useNavUtils } from '@assets/utils/NavUtils';
import { observer } from 'mobx-react';
import Table from 'react-bootstrap/Table';
import { useNavigate } from 'react-router-dom';
import store from 'src/model/Store';

export const TasksListPage = observer(() => {
    var tasks = store.tasks.entries;
    const nav = useNavUtils();
    return (
        <Table striped bordered hover>
            <thead>
                <tr>
                    <th>#</th>
                    <th>Icon</th>
                    <th>Name</th>
                    <th>Type</th>
                    <th>State</th>
                    <th>JS</th>
                    <th>Id</th>
                    <th>Edit</th>
                    <th>View</th>
                    <th>Delete</th>
                </tr>
            </thead>
            <tbody>
                {tasks.map((task, i) => (
                    <tr key={i}>
                        <td>{i}</td>
                        <td>
                            <img
                                style={{
                                    width: 48
                                }}
                                src={task.iconUrl}
                            />
                        </td>
                        <td>{task.name}</td>
                        <td>{task.type}</td>
                        <td>{task.state}</td>
                        <td style={{ maxWidth: 800, overflow: 'hidden' }}>
                            {JSON.stringify(task.toJS())}
                        </td>
                        <td>{task.id}</td>
                        <td>
                            <button
                                onClick={() => {
                                    nav.toEditTask(task.id);
                                }}
                            >
                                Edit
                            </button>
                        </td>
                        <td>
                            <button
                                onClick={() => {
                                    nav.toViewTask(task.id);
                                }}
                            >
                                View
                            </button>
                        </td>
                        <td>
                            <button
                                onClick={() => {
                                    if (window.confirm('Are you sure?')) {
                                        store.tasks.delete(task.id);
                                    }
                                }}
                            >
                                Delete
                            </button>
                        </td>
                    </tr>
                ))}
            </tbody>
        </Table>
    );
});
