import {
    AttachmentEntry,
    SaveState,
    TaskModel,
    TaskState,
    TaskType
} from '@assets/model/stores/Data/TaskModel/TaskModel';
import 'dropzone/dist/basic.css';
import 'dropzone/dist/dropzone.css';
import { observer } from 'mobx-react';
import { useEffect, useRef, useState } from 'react';
import { Card, Col, Container, Form, InputGroup, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { ButtonsView, MultiButton } from 'src/component/FormGroup/MultiButton';
import store, { useAssets, useTasks, useToaster } from 'src/model/Store';
import { MonthlyTask } from '../add/MonthlyTask';
import { OneTimeTask } from '../add/OneTimeTask';
import { WeeklyTask } from '../add/WeeklyTask';
import * as style from './EditTaskPage.module.less';
const { Dropzone } = require('dropzone');

import { Icon } from '@assets/component/Icon/Icon';
import { getFirebase } from '@assets/firebase/FirebaseAdapater';
import { ToggleButtonUI } from '@assets/component/ToggleButton/ToggleButton';
import { useNavUtils } from '@assets/utils/NavUtils';

export const EditTaskPage = observer(() => {
    const taskStore = useTasks();
    const assets = useAssets();
    const toaster = useToaster();
    const dropzoneRef = useRef();
    const nav = useNavUtils();
    const { id } = useParams();
    const [task, setTask] = useState<TaskModel>(null);

    const onToggle = (v: boolean) => {
        if (v) {
            task.setTaskState(TaskState.COMPLETE);
            toaster.addMessage('Task reported as completed');
        } else {
            task.setTaskState(TaskState.OPEN);
            toaster.addMessage('Task set as open');
        }
    };

    const onMonthlyReportYes = () => {
        task.logs.addEntry(new Date(), 'MONTHLY');
    };

    useEffect(() => {
        if (!task) return;
        const uploadFile = async (file: any) => {
            var path = task.getUserStoragePath(file.name);
            var url = await getFirebase().uploadFile(
                path,
                file,
                task.id,
                progress => {
                    console.log('from share', progress);
                }
            );

            var entry: AttachmentEntry = {
                url: url,
                name: file.name,
                type: file.type
            };
            task.files.push(entry);
            console.log('Uploaded: ', file.name);
        };

        const startUpload = async () => {
            const data = await store.workbox.messageSW({
                type: 'GET_FILES_SHARED'
            });

            if (!data || !data.files) return;
            for (var i = 0; i < data.files.length; i++) {
                await uploadFile(data.files[i]);
            }

            store.workbox.messageSW({
                type: 'CLEAR_FILES_SHARED'
            });
        };

        startUpload();
    }, [task]);

    useEffect(() => {
        var task: TaskModel = null;
        if (id == 'new') {
            task = new TaskModel();
        } else {
            task = taskStore.getCopy(id);
        }
        setTask(task);
        task.enableAutoSave();
    }, [id]);

    useEffect(() => {
        if (!task) return;

        if (task.ioState == SaveState.SAVED && id == 'new') {
            nav.toEditTask(task.id);
        }
    }, [task, task?.ioState]);
    useEffect(() => {
        if (task == null) return;

        let myDropzone = new Dropzone(dropzoneRef.current, {
            dictDefaultMessage: '',
            maxFilesize: 2, // MB
            url: '/',
            addRemoveLinks: false,
            method: 'put',
            chunking: true,
            forceChunking: true,
            autoQueue: false,
            autoProcessQueue: false,
            disablePreviews: true
        });

        myDropzone.on('addedfile', async function (file) {
            var path = task.getUserStoragePath(file.name);
            myDropzone.removeFile(file);
            var url = await getFirebase().uploadFile(
                path,
                file,
                task.id,
                progress => {
                    console.log('progress', progress);
                    myDropzone.emit('uploadprogress', file, progress);
                }
            );

            myDropzone.emit('complete', file);

            var entry: AttachmentEntry = {
                url: url,
                name: file.name,
                type: file.type
            };

            task.files.push(entry);
            setTimeout(() => {
                myDropzone.removeFile(file);
            }, 250);
        });

        return () => {
            while (myDropzone.files.length > 0) {
                myDropzone.removeFile(myDropzone.files[0]);
            }

            myDropzone.destroy();
        };
    }, [dropzoneRef, task]);

    const showStore = () => {
        store.modal.openSelectIcon(icon => {
            task.icon = icon;
        });
    };

    const onChangeTask = (v: string) => {
        task.type = v as TaskType;
    };

    var tasksUILoookup: { [key in TaskType]: React.ReactElement } = {
        ONE_TIME: <OneTimeTask task={task} />,
        WEEKLY: <WeeklyTask task={task} />,
        MONTHLY: <MonthlyTask task={task} />
    };
    var tasksButtons: ButtonsView[] = [
        { value: 'ONE_TIME', display: 'Once' },
        { value: 'WEEKLY', display: 'Week' },
        { value: 'MONTHLY', display: 'Month' }
    ];

    const createAttachment = (file: AttachmentEntry, i: number) => {
        var imageSrc = file.url;
        var docTypes = {
            'application/pdf': 'pdf',
            'application/msword': 'doc',
            'text/plain': 'txt'
        };
        //['pdf', 'doc', 'txt'];

        if (file.type.includes('image')) {
            imageSrc = file.url;
        } else {
            for (var key in docTypes) {
                if (file.type.includes(key)) {
                    imageSrc = assets.docIcons[docTypes[key]].url;
                    break;
                }
            }
        }
        if (!file.type.startsWith('image')) {
            var docType = 'doc';
            for (var key in docTypes) {
                if (file.name.includes(docTypes[key])) {
                    docType = docTypes[key];
                    break;
                }
            }
            imageSrc = assets.docIcons[docType].url;
        }

        const showAttachment = () => {
            store.modal.openViewAttachment(file.url);
        };
        return (
            <div
                key={i}
                className={style.attachedFile}
                onClick={showAttachment}
            >
                <img src={imageSrc} alt="file" />
            </div>
        );
    };
    if (!task) {
        return <div>Loading...</div>;
    }

    var taskUI = tasksUILoookup[task.type];
    const iconPath = task.icon ?? '';

    const attachments = (
        <div className={style.attachedFilesList}>
            {task.files.map((file, i) => createAttachment(file, i))}
        </div>
    );

    const dropzone = (
        <div
            ref={dropzoneRef}
            id="taskPageDropZone"
            className={style.taskPageDropZone}
        >
            <img
                className={style.uploadIcon}
                src={assets.sysIcons['upload'].url}
                alt="upload"
            />
        </div>
    );

    const tagLogs = task.logs.entries.map((log, i) => {
        return log.toJS();
    });

    return (
        <Card style={{ width: '400px' }}>
            <Card.Body>
                <Card.Title></Card.Title>
                <Card.Body>
                    <Form onSubmit={e => e.preventDefault()}>
                        <InputGroup className="mb-3">
                            <img
                                className={style.taskIcon}
                                onClick={showStore}
                                src={task.iconUrl}
                                alt="icon"
                            />

                            <Form.Control
                                type="text"
                                placeholder="Task Name"
                                value={task.name}
                                className={style.taskName}
                                onChange={e => {
                                    task.name = e.target.value;
                                }}
                            />

                            <MultiButton
                                selected={task.type}
                                buttons={tasksButtons}
                                onChange={onChangeTask}
                                label="Task Type"
                            />
                        </InputGroup>
                        <Form.Group className="mb-3">{taskUI}</Form.Group>
                        <Container>
                            <Row>
                                <Col xs={10} className="container p-0 ">
                                    {attachments}
                                </Col>
                                <Col xs={2} className="p-0">
                                    {dropzone}
                                </Col>
                            </Row>
                        </Container>
                        <Form.Group>
                            <div id="taskInfo" className={style.taskInfo}>
                                <Form.Control
                                    as="textarea"
                                    rows={4}
                                    wrap="hard"
                                    className={style.taskInfoTextaArea}
                                    value={task.desc}
                                    onChange={e => {
                                        task.desc = e.target.value;
                                    }}
                                />
                            </div>
                        </Form.Group>
                        <Container className="pt-3">
                            <Row>
                                <Col xs={4}>
                                    {task.type == TaskType.ONE_TIME && (
                                        <ToggleButtonUI
                                            value={
                                                task.state == TaskState.COMPLETE
                                            }
                                            onChange={v => {
                                                onToggle(v);
                                            }}
                                        />
                                    )}

                                    {task.type == TaskType.MONTHLY && (
                                        <Icon
                                            iconSys="yes"
                                            onClick={onMonthlyReportYes}
                                            size="S"
                                        />
                                    )}
                                </Col>
                                <Col xs={6}></Col>
                                <Col xs={2}>
                                    {task.ioState != SaveState.NOT_SAVED && (
                                        <Icon iconSys="saved" size="S" />
                                    )}
                                </Col>
                            </Row>
                        </Container>
                        {JSON.stringify(tagLogs)}
                    </Form>
                </Card.Body>
            </Card.Body>
        </Card>
    );
});
