import { debounce } from 'lodash';
import { observable } from 'mobx'

export interface ToasterMessage {
	message: string;
	id: number;
}

export class UIToasterStore {
	runningId: number = 0;
	constructor() {
	}

	private removeMessage() {
		this.messages.shift();
	}

	@observable
	accessor messages: ToasterMessage[] = []
	addMessage(message: string) {
		this.messages.push({ message: message, id: this.runningId });
		this.runningId += 1;

		setInterval(() => {
			this.removeMessage();
		}, 5000);
	}
}