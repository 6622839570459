import { observer } from 'mobx-react';
import { withRouter } from 'react-router-class-tools';
import * as datefns from 'date-fns';
import { Card, Col, Container, Row } from 'react-bootstrap';

import {
    LoadState,
    useTasksLog as useTasksView,
    useStore,
    useTasks,
    useToaster,
    useAnimations,
    useUI
} from '@assets/model/Store';
import {
    MonthlyTaskModel,
    OneTimeTaskModel,
    TaskModel,
    TaskState,
    TaskType
} from '@assets/model/stores/Data/TaskModel/TaskModel';
import { useNavUtils } from '@assets/utils/NavUtils';

import moment from 'moment';

import { i18n } from '../model/Translation';
import * as style from './Home.module.less';
import { Icon } from '@assets/component/Icon/Icon';
import { useState } from 'react';
import { PoistionTracker } from '@assets/component/AnimLayer/PositionTracker/PositionTracker';
import { HomeTaskView } from '@assets/component/HomeTaskView/HomeTaskView';
import { WeeklyStatsView } from '@assets/component/WeeklyStatsView/WeeklyStatsView';

const { t } = i18n;

const HomePage = observer(() => {
    const store = useStore();
    const tasks = useTasks();
    const toaster = useToaster();
    const nav = useNavUtils();
    const tasksView = useTasksView();
    const ui = useUI();

    const recurringTasks = tasks.weeklyTasks.map((task, i) => {
        const onReportTaskDone = () => {
            var anim = useAnimations();
            var from = anim.getPosition('weekly', task.id);
            var to = anim.getPosition('reporting_area', 'active');
            if (!from || !to) {
                return;
            }

            const animStore = useAnimations();
            animStore.animation = {
                from,
                to,
                icon: task.icon
            };

            const when = ui.selectedDay;
            const now = new Date();
            when.setHours(now.getHours());
            when.setMinutes(now.getMinutes());
            when.setSeconds(now.getSeconds());

            task.logs.addEntry(when, 'WEEKLY');
        };

        return (
            <Icon
                className={style.weeklyIconCell}
                id={task.id}
                key={task.id}
                iconTask={task.icon}
                size="L"
                onHold={onReportTaskDone}
                // onClick={() => nav.toViewTask(task.id)}
                tracker="weekly"
            />
        );
    });

    return (
        <Container className="p-0">
            {/* <Row className="justify-content-between pt-2">
                <Col className="p-0">
                    <HomeTaskView />
                </Col>
            </Row> */}
            {/* <Row>
                        <Col>--</Col>
                    </Row> */}
            {/* <Row className="justify-content-between">
                        <Col>{monthlyTasks}</Col>
                    </Row> */}
            <Row>
                <Col className="pt-2 d-flex flex-wrap">{recurringTasks}</Col>
            </Row>
            <Row className="pt-2">
                <WeeklyStatsView />
            </Row>
        </Container>
    );
});

export default withRouter(HomePage);
