import { getFirebase } from '@assets/firebase/FirebaseAdapater';
import { useLists } from '@assets/model/Store';
import { computed, observable } from 'mobx';
import { ISerializeDoc, parseDate } from '../../StoreUtils';
import { PreviewFetcher } from './PreviewFetcher';

export type ContentCategory = 'Movies' | 'TVShows' | 'Books' | 'Music' | 'PCGames';

export class ListContentEntry implements ISerializeDoc<ListContentEntry> {
	@observable
	accessor id: string;

	@observable
	accessor name: string;

	@observable
	accessor categoryId: string;

	@computed
	get category(): string {
		const lists = useLists();
		const category = lists.categories.find(c => c.id == this.categoryId);
		return category?.name ?? '';
	}

	@observable
	accessor created: Date = new Date();

	@observable
	accessor genres: string[] = [];

	@observable
	accessor url: string = "";

	@observable
	accessor creators: { name: string, job: string }[] = [];

	@observable
	accessor thumbnailURL: string = "";

	@observable
	accessor releaseDate: Date = new Date();

	@observable
	accessor overview: string = "";

	@observable
	accessor user_rating: number = -1;

	@observable
	accessor user_notes: string = "";


	@observable
	accessor user_dating_date: Date = null;

	static readonly PATH: string[] = ['list_content'];

	constructor() {
		this.id = getFirebase().generateId(ListContentEntry.PATH);
	}

	async save() {
		await this.tryToEnhance();
		var d: ListContentEntry = this;
		return getFirebase().saveDoc(ListContentEntry.PATH, d);
	}

	async delete() {
		return getFirebase().deleteData(ListContentEntry.PATH, this.id);
	}

	async tryToEnhance() {
		var prevFetch = new PreviewFetcher();
		var url = null;
		var didEnhance = false;
		switch (this.category) {
			case 'Movies':
				await prevFetch.enhanceMovie(this.name, this);
				break;
			case 'TVShows':
				await prevFetch.enhanveTvShow(this.name, this);
				break;
			case 'Books':
				await prevFetch.enhanceBookCover(this.name, this);
				break;
			case 'Music':
				await prevFetch.enhanceMusicArtist(this.name, this);
				break;
			case 'PCGames':
				await prevFetch.enhanceGame(this.name, this);
				break;
		}
	}

	toJS() {
		return {
			id: this.id,
			name: this.name,
			categoryId: this.categoryId,
			genres: this.genres,
			url: this.url,
			creators: this.creators,
			releaseDate: this.releaseDate,
			thumbnailURL: this.thumbnailURL,
			created: this.created,
			overview: this.overview,
			user_rating: this.user_rating,
			user_notes: this.user_notes,
			user_dating_date: this.user_dating_date,
		}
	}

	fromJS(json: any): ListContentEntry {
		this.id = json['id'];
		this.name = json['name'];
		this.categoryId = json['categoryId'];
		this.genres = json['genres'] ?? [];
		this.url = json['url'] ?? "";
		this.creators = json['creators'] ?? [];
		this.overview = json['overview'] ?? "";
		this.user_notes = json['user_notes'] ?? "";
		this.user_rating = json['user_rating'] ?? -1;
		if (json['releaseDate']) {
			this.releaseDate = parseDate(json['releaseDate']);
		} else {
			this.releaseDate = null;
		}

		if (json['user_dating_date']) {
			this.user_dating_date = parseDate(json['user_dating_date']);
		} else {
			this.user_dating_date = null;
		}

		this.thumbnailURL = json['thumbnailURL'] ?? null;

		if (json['created']) {
			this.created = parseDate(json['created']);
		} else {
			this.created = new Date();
		}

		if (json['created']) {
			this.created = parseDate(json['created']);
		} else {
			this.created = new Date();
		}
		return this;
	}

}

export class ListCategoryModel implements ISerializeDoc<ListCategoryModel> {
	@observable
	accessor id: string = '';

	@observable
	accessor name: string = '';

	@observable
	accessor categories: string[] = [];

	static readonly PATH: string[] = ['list_categories'];

	constructor() {
		this.id = getFirebase().generateId(ListCategoryModel.PATH);
	}

	save() {
		var d: ListCategoryModel = this;
		getFirebase().saveDoc(ListCategoryModel.PATH, d);
	}

	toJS() {
		return {
			id: this.id,
			name: this.name,
			categories: this.categories
		}
	}

	fromJS(json: any): ListCategoryModel {
		this.id = json['id'];
		this.name = json['name'];
		this.categories = json['categories'];


		return this;
	}

}

export class FilterContentUI {
	@observable
	accessor rating: number;

	@observable
	accessor show_only_not_rated: boolean;

	@observable
	accessor contentType: ContentCategory | null;

	@observable
	accessor search: string;

	constructor() {
		this.reset();
	}

	reset() {
		this.rating = -1;
		this.contentType = null
		this.search = "";
		this.show_only_not_rated = false;
	}
}

export class ListsStore {
	@observable
	accessor categories: ListCategoryModel[] = []

	@observable
	accessor contentList: ListContentEntry[] = []

	@observable
	accessor filterUI: FilterContentUI = new FilterContentUI();

	constructor() {
		this.categories = []
		this.contentList = [];
	}


	deleteCategory(id: string) {
		getFirebase().deleteData(ListCategoryModel.PATH, id)
	}

	init() {
		getFirebase().listenData(ListCategoryModel.PATH, (data) => {
			this.categories = data

		}, ListCategoryModel);

		getFirebase().listenData(ListContentEntry.PATH, (data) => {
			this.contentList = data
		}, ListContentEntry);
	}
}