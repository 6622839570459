import { observer } from 'mobx-react';
import { useEffect, useRef } from 'react';
import * as style from './PositionTracker.module.less';
import { Point, PositionInfo } from '@assets/model/stores/UI/AnimationStore';
import { useAnimations } from '@assets/model/Store';

interface PositionTrackerProps extends PositionInfo {
    className?: string;
}

export const PoistionTracker = observer((props: PositionTrackerProps) => {
    const el = useRef(null);
    const cls = [style.tracker, props.className].join(' ');
    useEffect(() => {
        const updatePosition = () => {
            if (el.current) {
                var parent = el.current.parentElement;
                const rect = parent.getBoundingClientRect();
                // console.log(
                //     `PositionTracker: ${props.type}->${props.id} ${rect.left} ${rect.top}`
                // );
                const pos = {
                    x: rect.left + rect.width / 2,
                    y: rect.top + rect.height / 2
                };
                const animStore = useAnimations();
                animStore.reportPosition(props, pos);
            }
        };

        const timer = setInterval(updatePosition, 1000);

        return () => {
            clearInterval(timer);
        };
    }, [el.current]);

    return <div ref={el} className={style.tracker} />;
});
