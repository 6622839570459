.AnimationLayer {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1000;
    background: transparent;
    pointer-events: none;
}

@keyframes OffsetAnimation {
    0% {
        offset-distance: 0%;
        opacity: 0;
    }
    5% {
        opacity: 0.3;
    }
    15% {
        opacity: 1;
    }

    70% {
        opacity: 1;
    }
    95% {
        offset-distance: 100%;
        opacity: 0;
    }

    100% {
        opacity: 0;
    }
}

@keyframes ScaleAnimation {
    0% {
        transform: scale(0);
        filter: drop-shadow(0px 0px 0px #000000);
    }

    50% {
        transform: scale(2.8);
        filter: drop-shadow(4px 10px 4px #000000);
    }

    100% {
        transform: scale(0.01);
        filter: drop-shadow(0px 0px 0px #000000);
    }
}

.PlaneContainer {
    position: absolute;
    animation: OffsetAnimation ease-in-out;
    animation-fill-mode: forwards;
    animation-delay: 0s;
    offset-rotate: 0deg;
}

.PlaneScaleAnim {
    animation: ScaleAnimation ease-in-out;
    animation-fill-mode: forwards;
    animation-delay: 0s;
    position: absolute;
    top: -20px;
    left: -20px;
}
