.dayTitle {
    text-align: center;
    width: 120px !important;
    max-width: 120px !important;
    padding-left: 4px;
    padding-right: 4px;
}

.dayContainer {
    width: 90px;
}
.weeklyIconCell {
    flex: 0 0 0;
    max-height: 30px;
    height: 30px;
    opacity: 0;
}

.fullSizeIcon {
    flex-basis: 30px !important;
    opacity: 1 !important;
}

.animateIcon {
    animation: grow 1s;
    animation-delay: 1.5s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}

.activeDay {
    font-weight: 600;
}

.dayLabel {
    cursor: pointer;
}

@keyframes grow {
    0% {
        flex-basis: 0px;
    }

    33% {
        opacity: 0;
    }
    100% {
        opacity: 1;
        flex-basis: 30px;
    }
}
