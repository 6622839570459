.preview {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.title {
    font-size: 45px;
    text-align: center;
    font-weight: bold;
    padding-bottom: 20px;
}

.overview {
    padding-bottom: 20px;
}
