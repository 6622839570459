import { observer } from 'mobx-react';
import { useAssets, useModal } from 'src/model/Store';
import * as style from './AttachmentViewModal.module.less';

import { useEffect, useRef } from 'react';
function get_url_extension(url) {
    return url.split(/[#?]/)[0].split('.').pop().trim().toLowerCase();
}

export const AttachmentViewModal = observer(() => {
    const pdfRef = useRef(null);
    const modalStore = useModal();
    const asset = modalStore.attachmentUrl;
    const ext = get_url_extension(asset);

    if (ext == 'pdf') {
        var src =
            'https://docs.google.com/viewer?url=' +
            encodeURIComponent(asset) +
            '&embedded=true';
        return <iframe src={src} height="600px" width="100%"></iframe>;
    } else if (ext == 'txt' || ext == 'json') {
        return <iframe src={asset} className={style.txt} />;
    } else {
        //Image
        return <img src={asset} className={style.img} />;
    }

    return 'No Preview';
});

export default AttachmentViewModal;
