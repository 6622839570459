import { list } from 'firebase/storage';
import { IDType } from 'mobx-restful';

export default {
	welcome: 'Welcome',
	upstream_projects: 'Upstream projects',
	home_page: 'Home Page',
	addtask: 'Add Task',
	tasks: 'Tasks',
	icons: 'Icons',
	lists: 'Lists',
	ai: 'AI',
} as const;
