import { useWorkbox } from '../Store';

//FUTURE PULL UPDATES IN SERVICE!
export class CacheStore {
	store(cacheKey: string, snapshot: any) {
		localStorage.setItem(cacheKey, JSON.stringify(snapshot));
		// const wb = useWorkbox();
	}

	get(cacheKey: string) {
		if (localStorage.getItem(cacheKey)) {
			return JSON.parse(localStorage.getItem(cacheKey));
		}

		return null;
	}

	has(cacheKey: string) {
		return localStorage.getItem(cacheKey) != null;
	}
}