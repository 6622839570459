import { observer } from 'mobx-react';
import { Button, ButtonGroup, Form } from 'react-bootstrap';
import { ButtonsView, MultiButton } from 'src/component/FormGroup/MultiButton';
import {
    MonthlyTaskModel,
    TaskModel
} from '@assets/model/stores/Data/TaskModel/TaskModel';

export const MonthlyTask = observer((props: { task: TaskModel }) => {
    const { task } = props;
    const specific = task.getSpecific<MonthlyTaskModel>();

    let buttons: ButtonsView[] = [
        { value: 'start', display: 'Start' },
        { value: 'end', display: 'End' }
    ];

    const onClick = (val: string) => {
        specific.monthly = val;
    };
    return (
        <></>
        // <MultiButton
        //     selected={specific.monthly}
        //     buttons={buttons}
        //     onChange={onClick}
        //     label="Monthly Task"
        // />
    );
});
