.taskPageDropZone {
    margin: 0 !important;
    padding: 0 !important;
    width: 64px;
    height: 64px;
    border-radius: var(--bs-border-radius) !important;
    border: var(--bs-border-width) solid var(--bs-border-color) !important;
    &:hover {
        filter: drop-shadow(0 0 1px var(--bs-primary));
    }
}

.filterButton {
    position: fixed;
    right: 10px;
    bottom: 60px;
    padding: 10px;
    z-index: 5000;
}

.uploadIcon {
    width: 64px;
    height: 64px;
    pointer-events: none;
}

.categoryEntry {
    cursor: pointer;
    width: calc(33%);
    height: calc(33%);
    padding: 10px;
    margin: 0;
    padding: 0;
    border-radius: 10%;
    border: 1px dotted black;
}

.contentItemsContainer {
    flex-wrap: wrap;
    width: 100%;
    height: 100%;
    display: flex;
    padding: 0;
    margin: 0;
}
