.background {
    background-color: rgba(#00000099);
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: opacity 0.5s;
    pointer-events: none;
}

.active {
    opacity: 1;
    z-index: 10000 !important;
}

.loader {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 10%;
    width: 200px;
}
