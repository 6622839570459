import { auto } from 'browser-unhandled-rejection';
import { configure } from 'mobx';
import { render } from 'react-dom';
import { serviceWorkerUpdate } from 'web-utility';
import { getFirebase } from './firebase/FirebaseAdapater';
import { PageFrame } from './page';
import { Workbox } from 'workbox-window';
import { useStore } from './model/Store';
import { createRoot } from 'react-dom/client';
const wb = new Workbox('/sw.js');

useStore().workbox = wb;

self.addEventListener('message', ({ data }) => {
    console.log('Browser Worker message:', data);
});

const messageTest = async () => {
    const swVersion = await wb.messageSW({ type: 'GET_VERSION' });
    console.log('Service Worker version:', swVersion);
};

messageTest();

wb.addEventListener('message', ({ data }) => {
    console.log('Browser #2 Worker message:', data);
});

wb.register();
wb.messageSW({ type: 'ping_test' });

getFirebase().init();

auto();

configure({ enforceActions: 'never' });

self.addEventListener('unhandledrejection', ({ reason }) => {
    const { message } = reason as Error;

    if (message) self.alert(message);
});

const { serviceWorker } = window.navigator,
    NODE_ENV = process.env.NODE_ENV || 'development';

if (NODE_ENV !== 'development')
    serviceWorker
        ?.register('sw.js')
        .then(serviceWorkerUpdate)
        .then(worker => {
            if (
                window.confirm(
                    'New version of this Web App detected, update now?'
                )
            )
                worker.postMessage({ type: 'SKIP_WAITING' });
        });

serviceWorker?.addEventListener('controllerchange', () =>
    window.location.reload()
);

const root = createRoot(document.querySelector('#app'));
root.render(<PageFrame />);
