import { FirebaseApp, initializeApp } from 'firebase/app';
import {
    getFirestore,
    collection,
    getDocs,
    addDoc,
    doc,
    onSnapshot,
    Firestore,
    setDoc,
    Unsubscribe,
    deleteDoc
} from 'firebase/firestore';
import {
    getAuth,
    onAuthStateChanged,
    signInWithEmailAndPassword
} from 'firebase/auth';
import { TaskModel } from '@assets/model/stores/Data/TaskModel/TaskModel';
import store, { useCache } from 'src/model/Store';
import { remove } from 'lodash';
import {
    getDownloadURL,
    getStorage,
    ref,
    updateMetadata,
    uploadBytes,
    uploadBytesResumable
} from 'firebase/storage';
import { ISerializeDoc } from '@assets/model/stores/StoreUtils';
import { cache } from 'web-utility';

interface FileUpload {
    url: string;
    name: string;
    thumbnail: string;
}

class FirebaseAdapater {
    unsubList: Unsubscribe[] = [];

    buildPath(sectionName: string[], id?: string): string {
        const sections = ['users', this.userId, ...sectionName];
        if (id) {
            sections.push(id);
        }
        return sections.join('/');
    }

    generateId(sectionName: string[]): string {
        return doc(collection(this.db, this.buildPath(sectionName))).id;
    }

    async saveDoc<T extends ISerializeDoc<T>>(
        sectionName: string[],
        data: T
    ): Promise<string> {
        //check if it has ID or not - add or update
        var js = data.toJS();
        if (js.id) {
            await setDoc(doc(this.db, this.buildPath(sectionName), js.id), js);

            return js.id;
        } else {
            throw 'Expecting valid ID';
        }
    }

    async uploadFile(
        storagePath: string,
        file: File,
        taskId: string,
        onProgress: (progress: number) => void = null
    ): Promise<string> {
        const storage = getStorage(this.app);
        const storageRef = ref(storage, storagePath);

        const upload_task = uploadBytesResumable(storageRef, file);

        upload_task.on(
            'state_changed',
            snapshot => {
                // Observe state change events such as progress, pause, and resume
                // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                const progress =
                    (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                if (onProgress) {
                    onProgress(progress);
                }
            },
            error => {},
            () => {}
        );

        const metadata = {
            customMetadata: {
                taskId
            }
        };
        var snapshot = await upload_task;
        await updateMetadata(storageRef, metadata);
        const url = await getDownloadURL(snapshot.ref);

        return url;
    }

    deleteData(sectionName: string[], id: string) {
        const path = this.buildPath(sectionName, id);
        return deleteDoc(doc(this.db, path));
    }

    unsub(fn: Unsubscribe) {
        if (this.unsubList.indexOf(fn) >= 0) {
            fn();
            remove(this.unsubList, fn);
        } else {
            console.error('Unsub not found');
        }
    }
    listenData<T extends ISerializeDoc<T>>(
        subcol: string[],
        callback: (data: T[]) => void,
        ctor: { new (): T }
    ) {
        //todo: FIND iD AND UPDATE DOC ETC

        const cache = useCache();
        const cacheKey = subcol.join('.');
        if (cache.has(cacheKey)) {
            console.log('*** Used cache prior to fetching');
            var json = cache.get(cacheKey);
            var docs: T[] = JSON.parse(json).map(doc => {
                var d = new ctor();
                d.fromJS({ ...doc });

                return d;
            });
            callback(docs);
        }

        const path = this.buildPath(subcol);
        const unsub = onSnapshot(collection(this.db, path), collection => {
            var json = JSON.stringify(
                collection.docs.map(doc => ({ ...doc.data(), id: doc.id }))
            );
            cache.store(cacheKey, json);

            console.log('*** Firebase snapshot');

            var docs: T[] = collection.docs.map(doc => {
                var d = new ctor();
                d.fromJS({ ...doc.data(), id: doc.id });

                return d;
            });
            callback(docs);
        });

        this.unsubList.push(unsub);
        return unsub;
    }
    app: FirebaseApp;
    db: Firestore;
    userId: string;

    init() {
        const firebaseConfig = {
            apiKey: 'AIzaSyD8QDm6AKiD-O8TvWSi2I9Y-AsxmzhCuK8',
            authDomain: 'adhd-e3dad.firebaseapp.com',
            projectId: 'adhd-e3dad',
            storageBucket: 'adhd-e3dad.appspot.com',
            messagingSenderId: '405904685378',
            appId: '1:405904685378:web:9c8e26f78a9622902eb0db'
        };

        // Initialize Firebase
        console.log('Initializing Firebase');
        this.app = initializeApp(firebaseConfig);
        this.db = getFirestore(this.app);
        var auth = getAuth(this.app);
        const cache = useCache();
        if (cache.has('userId')) {
            this.userId = cache.get('userId');
            store.init(this.userId);
        }

        onAuthStateChanged(auth, user => {
            if (user) {
                console.log('Logged in!');
                this.userId = user.uid;
                //Init app refactor
                cache.store('userId', user.uid);
                store.init(user.uid);
            } else {
                var pass = window.prompt('Password?');
                signInWithEmailAndPassword(auth, 'nixaboo@gmail.com', pass);
            }
        });
    }
}

const mFirebaseService = new FirebaseAdapater();
export function getFirebase() {
    return mFirebaseService;
}
