.recording {
    filter: drop-shadow(0 0 1px var(--bs-primary));
}

.recordIcon {
    width: 48px;
    height: 38px;
    cursor: pointer;
    position: relative;
    left: -20px;
    &:hover {
        animation: wiggle 0.5s 1 ease-in-out;
    }
}

@keyframes wiggle {
    0% {
        transform: rotate(0deg) scale(1);
    }

    40% {
        transform: rotate(-5deg) scale(1.2);
    }
    80% {
        transform: rotate(5deg) scale(1);
    }
    100% {
        transform: rotate(0deg);
    }
}

@keyframes activate_animation {
    0% {
        transform: scale(1) rotate(0deg);
    }
    20% {
        transform: scale(1) rotate(3deg);
    }
    40% {
        transform: scale(1) rotate(-3deg);
    }
    50% {
        transform: scale(1.3) rotate(0deg);
    }

    70% {
        transform: scale(0.9) rotate(0deg);
    }
    100% {
        transform: scale(1) rotate(0deg);
    }
}
