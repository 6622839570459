// <div class="checkbox-wrapper-26">
//   <input type="checkbox" id="_checkbox-26">
//   <label for="_checkbox-26">
//     <div class="tick_mark"></div>
//   </label>
// 	</div>

import { observer } from 'mobx-react';
import * as style from './ToggleButton.module.less';

interface ToggleButtonProps {
    value: boolean;
    onChange: (value: boolean) => void;
}

export const ToggleButtonUI = observer((props: ToggleButtonProps) => {
    const onClick = () => {
        props.onChange(!props.value);
    };

    return (
        <div className={style.checkbox}>
            <label>
                <input
                    type="checkbox"
                    checked={props.value}
                    onChange={onClick}
                />
                <div></div>
            </label>
        </div>
    );
});
