import { useTasks, useTasksLog, useUI } from '@assets/model/Store';
import {
    TaskState,
    TaskType
} from '@assets/model/stores/Data/TaskModel/TaskModel';
import { useNavUtils } from '@assets/utils/NavUtils';
import { observer } from 'mobx-react';
import moment from 'moment';
import { useRef, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { PoistionTracker } from '../AnimLayer/PositionTracker/PositionTracker';
import { Icon } from '../Icon/Icon';
import * as style from './WeeklyStatsView.module.less';

export const WeeklyStatsView = observer(() => {
    const tasksView = useTasksLog();
    const tasks = useTasks();
    const nav = useNavUtils();
    const ui = useUI();
    const [triggerDraw, setDraw] = useState(false);

    const itemsRenderedRef = useRef([]);
    const itemsRendered = itemsRenderedRef.current;

    const selectedDay = ui.selectedDay;
    const firstRender = itemsRendered.length == 0;
    const statsView = tasksView.weeklyStats.map(day => {
        const iconsEl = [];
        const sorted = day.list
            .slice()
            .sort((a, b) => a.when.getTime() - b.when.getTime());

        sorted.forEach(key => {
            var task = tasks.get(key.taskId);
            if (!task) {
                return;
            }

            const removeEntry = () => {
                var task = tasks.get(key.taskId);
                task.logs.removeEntry(key.id);
                if (task.type == TaskType.ONE_TIME) {
                    task.setTaskState(TaskState.OPEN);
                }
            };
            const iconStyle = [style.weeklyIconCell];
            if (firstRender || itemsRendered.includes(key.id)) {
                iconStyle.push(style.fullSizeIcon);
                itemsRendered.push(key.id);
            } else {
                iconStyle.push(style.animateIcon);

                setTimeout(() => {
                    itemsRendered.push(key.id);
                    setDraw(!triggerDraw);
                }, 3000);
            }

            iconsEl.push(
                <Icon
                    className={iconStyle.join(' ')}
                    key={key.id}
                    iconTask={task.icon}
                    size="S"
                    // onClick={() => {
                    //     console.log(key.when.getTime());
                    // }}
                    onHold={removeEntry}
                />
            );
        });

        var dayDate = moment(day.date);
        var selectedDate = moment(selectedDay);
        const isSelected = dayDate.isSame(selectedDate, 'day');
        return (
            <Col
                key={day.date.getTime()}
                className={'justify-content-center p-0 ' + style.dayTitle}
            >
                <div
                    onClick={() => (ui.selectedDay = day.date)}
                    className={
                        (isSelected ? style.activeDay : '') +
                        ' ' +
                        style.dayLabel
                    }
                >
                    {moment(day.date).format('ddd')}
                </div>
                {isSelected ? (
                    <PoistionTracker id={'active'} type="reporting_area" />
                ) : null}
                <div
                    className={
                        'd-flex flex-row flex-wrap justify-content-center w-100 align-items-start ' +
                        style.dayContainer
                    }
                >
                    {iconsEl}
                </div>
            </Col>
        );
    });

    return (
        <Container className="p-3">
            <Row className="d-flex justify-content-between">{statsView}</Row>
        </Container>
    );
    return statsView;
});
