import { HTTPClient } from 'koajax';
import { getFunctions, httpsCallable } from "firebase/functions";
import { ListContentEntry } from './ListsStore';
import moment from 'moment';

const API_SERVER = 'https://api.ifgoodyes.com';

const MOVIE_GENRES = {
	"genres": [
		{
			"id": 28,
			"name": "Action"
		},
		{
			"id": 12,
			"name": "Adventure"
		},
		{
			"id": 16,
			"name": "Animation"
		},
		{
			"id": 35,
			"name": "Comedy"
		},
		{
			"id": 80,
			"name": "Crime"
		},
		{
			"id": 99,
			"name": "Documentary"
		},
		{
			"id": 18,
			"name": "Drama"
		},
		{
			"id": 10751,
			"name": "Family"
		},
		{
			"id": 14,
			"name": "Fantasy"
		},
		{
			"id": 36,
			"name": "History"
		},
		{
			"id": 27,
			"name": "Horror"
		},
		{
			"id": 10402,
			"name": "Music"
		},
		{
			"id": 9648,
			"name": "Mystery"
		},
		{
			"id": 10749,
			"name": "Romance"
		},
		{
			"id": 878,
			"name": "Science Fiction"
		},
		{
			"id": 10770,
			"name": "TV Movie"
		},
		{
			"id": 53,
			"name": "Thriller"
		},
		{
			"id": 10752,
			"name": "War"
		},
		{
			"id": 37,
			"name": "Western"
		}
	]
};

const TV_GENRES = {
	"genres": [
		{
			"id": 10759,
			"name": "Action & Adventure"
		},
		{
			"id": 16,
			"name": "Animation"
		},
		{
			"id": 35,
			"name": "Comedy"
		},
		{
			"id": 80,
			"name": "Crime"
		},
		{
			"id": 99,
			"name": "Documentary"
		},
		{
			"id": 18,
			"name": "Drama"
		},
		{
			"id": 10751,
			"name": "Family"
		},
		{
			"id": 10762,
			"name": "Kids"
		},
		{
			"id": 9648,
			"name": "Mystery"
		},
		{
			"id": 10763,
			"name": "News"
		},
		{
			"id": 10764,
			"name": "Reality"
		},
		{
			"id": 10765,
			"name": "Sci-Fi & Fantasy"
		},
		{
			"id": 10766,
			"name": "Soap"
		},
		{
			"id": 10767,
			"name": "Talk"
		},
		{
			"id": 10768,
			"name": "War & Politics"
		},
		{
			"id": 37,
			"name": "Western"
		}
	]
};

export class PreviewFetcher {

	//https://api.themoviedb.org/3/search/tv


	async enhanceMovie(movie: string, content: ListContentEntry) {
		var api =
			'https://api.themoviedb.org/3/search/movie?query={SEARCH}&include_adult=true&language=en-US&page=1&region=US';

		var url = api.replace('{SEARCH}', movie);

		return await this.enhanceWithTMDB(url, MOVIE_GENRES, content);
	};
	async enhanceBookCover(book: string, content: ListContentEntry) {
		const api = "https://www.googleapis.com/books/v1/volumes?q={BOOK}&maxResults=1"
		var url = api.replace('{BOOK}', book);

		var http = new HTTPClient();
		var res: any = await http.get(url, { accept: 'application/json' });
		try {
			content.thumbnailURL = res.body.items[0].volumeInfo.imageLinks.thumbnail;
			return true;
		} catch (e) {
			return false;
		} return;
		return false;
	}

	async enhanveTvShow(tvShow: string, content: ListContentEntry) {
		const api =
			'https://api.themoviedb.org/3/search/tv?query={SEARCH}&include_adult=true&language=en-US&page=1&region=US';

		const url = api.replace('{SEARCH}', tvShow);
		return await this.enhanceWithTMDB(url, TV_GENRES, content);
	};

	async enhanceMusicArtist(artist: string, content: ListContentEntry) {
		try {
			const api = `${API_SERVER}/rest/data/getArtist?artist=${artist}`;
			const http = new HTTPClient();
			var res = await http.get(api);
			var r = res.body;
			content.thumbnailURL = r as string;
			return true;
		} catch (e) {
			return false;
		}

	}

	async enhanceGame(game: string, content: ListContentEntry) {
		try {
			const api = `${API_SERVER}/rest/data/getGameCover?game=${game}`;
			const http = new HTTPClient();
			var res = await http.get(api);
			var r = JSON.parse(res.body as string);
			content.thumbnailURL = r.thumbnail;
			content.overview = r.summary; // + '\r\n' + r.storyline;
			return true;
		} catch (e) {
			return false;
		}

	}

	private async enhanceWithTMDB(url: string, genreTable: any, content: ListContentEntry) {
		const token =
			'Bearer eyJhbGciOiJIUzI1NiJ9.eyJhdWQiOiJkNjMyNGFhZjhkYWU3ZTk3N2MxYjU1ZmZjYzAxOGMyZiIsIm5iZiI6MTcyMzg4NTM4NS44NjQ2OSwic3ViIjoiNjZjMDY2YmY3NDEzOGUxMGQ3MDNiYjI5Iiwic2NvcGVzIjpbImFwaV9yZWFkIl0sInZlcnNpb24iOjF9.QuWDtdToxlNI-uBybJpUJA780EvZl1gVYUXVVJxy8-c';
		const client = new HTTPClient().use(
			async ({ request: { method, path, headers }, response }, next) => {
				if (token) headers['Authorization'] = 'token ' + token;

				await next();
			}
		);


		var res = await client.get(url, { accept: 'application/json' });

		try {
			var r: any = (res.body as any).results[0];
			const baseURL = 'https://image.tmdb.org/t/p/w500';
			const date = r.release_date || r.first_air_date;
			var fields = {
				poster: baseURL + r.poster_path,
				genres: r.genre_ids.map((g: any) => genreTable.genres.find((x: any) => x.id == g).name),
				overview: r.overview,

				vote_average: r.vote_average,
			};

			content.releaseDate = date ? moment(date).toDate() : null;
			content.thumbnailURL = fields.poster;
			content.genres = fields.genres;
			content.overview = fields.overview;
			return true;
		}
		catch (e) {
			return false;
		}
		return false;
	}
}
