.imagePreview {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.imageContainer {
    width: 100%;
    margin: auto;
    height: 300px;
    border-radius: 10px;
    border: 1px solid black;
}

.typeDropdown {
    position: absolute;
    right: 15;
}
.uploadFile {
    height: 32px !important;
}

.promptInput {
    height: 100px;
    width: 80%;
    resize: none;
    outline: none !important;
    box-shadow: none !important;
}

.recorder {
    position: absolute;
    right: 20px !important;
    top: 70px;
    left: unset !important;
}

.imageSwitch {
    position: absolute;
    right: 15px !important;
    transform: translateY(-25px);
    left: unset !important;
}
