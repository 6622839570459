import { observer } from 'mobx-react';
import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useAssets, useModal, useStore } from 'src/model/Store';
import * as style from './IconSelectionModal.module.less';
import { ModalType } from '@assets/model/stores/UI/UIModalStore';
import IconSeletionModal from './IconsSelection/IconSelectionModal';
import AttachmentViewModal from './AttachmentView/AttachmentViewModal';
import { ContentFilter } from '@assets/page/Lists/components/ContentFilter/ContentFilter';

export const ModalContainer = observer(() => {
    const modalStore = useModal();
    const icons = useAssets().taskIcons;
    var iconKeys = Object.keys(icons).sort();
    const show = modalStore.activeModal != 'None';

    const modalUILookup: { [key in ModalType]: React.ReactElement } = {
        None: <div></div>,
        IconSelect: <IconSeletionModal />,
        ViewAttachment: <AttachmentViewModal />,
        ModalFilter: <ContentFilter />
    };

    const handleClose = () => {
        modalStore.closeModal();
    };

    const uiElem = modalUILookup[modalStore.activeModal];
    return (
        <>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{modalStore.modalTitle}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{uiElem}</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
});

export default ModalContainer;
