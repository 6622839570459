import { observable } from 'mobx'

export type Point = { x: number, y: number };
interface Animation {
	from: Point,
	to: Point,
	icon: string
}

export type PositionCategory = 'weekly' | 'reporting_area';
export type PositionInfo = {
	type: PositionCategory,
	id: string
}

type PositionById = { [id: string]: Point };
export class AnimationStore {
	@observable
	accessor animation: Animation = null;


	positions: { [category: string]: PositionById } = {};

	reportPosition(info: PositionInfo, pos: Point) {
		if (!this.positions[info.type]) {
			this.positions[info.type] = {};
		}

		this.positions[info.type][info.id] = pos;
	}

	getPosition(type: PositionCategory, id: string): Point {
		if (!this.positions[type]) {
			return null;
		}

		if (!this.positions[type][id]) {
			return null;
		}

		return this.positions[type][id];
	}
}