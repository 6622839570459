import { Option, Select } from 'idea-react';
import { observer } from 'mobx-react';
import { Container, Nav, Navbar, Offcanvas } from 'react-bootstrap';

import { mainNavLinks } from '../meta';
import { i18n, LanguageName } from '../model/Translation';
import * as style from './MainNavigator.module.less';
import { Icon } from '@assets/component/Icon/Icon';
import { useNavUtils } from '@assets/utils/NavUtils';
import { useUI } from '@assets/model/Store';

export const MainNavigator = observer(() => {
    const { t, currentLanguage } = i18n;
    const ui = useUI();

    const handleClose = () => {
        ui.isMenuOpen = false;
    };

    return (
        <>
            <Icon
                iconSys="menu"
                className={style.menuButton}
                size="M"
                onClick={() => {
                    ui.isMenuOpen = true;
                }}
            />

            <Icon
                iconSys="back"
                size="M"
                className={style.backButton}
                onClick={() => {
                    history.back();
                }}
            />

            <Offcanvas show={ui.isMenuOpen} onHide={handleClose}>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Menu</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Nav
                        className="flex-column"
                        onSelect={() => {
                            ui.isMenuOpen = false;
                        }}
                    >
                        {mainNavLinks().map(({ title, href }) => (
                            <Nav.Item key={title}>
                                <Nav.Link
                                    key={title}
                                    href={href}
                                    className="fs-1"
                                >
                                    {title}
                                </Nav.Link>
                            </Nav.Item>
                        ))}
                    </Nav>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    );
});
