import { observer } from 'mobx-react';
import * as style from './LoadingModal.module.less';
import { useAssets, useModal } from '@assets/model/Store';

export const LoadingModal = observer(() => {
    const assets = useAssets();
    const shouldBlock = useModal().shouldBlockUI;

    const loaderSvg = assets.docIcons['loading'];
    const isActive = shouldBlock ? style.active : '';
    return (
        <div className={style.background + ' ' + isActive}>
            <div className="d-flex justify-content-center">
                <svg
                    className={style.loader}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 200 200"
                    width={200}
                    height={200}
                >
                    <rect
                        fill="#0D6EFD"
                        stroke="#0D6EFD"
                        stroke-width="15"
                        width="30"
                        height="30"
                        x="25"
                        y="50"
                    >
                        <animate
                            attributeName="y"
                            calcMode="spline"
                            dur="2"
                            values="50;120;50;"
                            keySplines=".5 0 .5 1;.5 0 .5 1"
                            repeatCount="indefinite"
                            begin="-.4"
                        ></animate>
                    </rect>
                    <rect
                        fill="#0D6EFD"
                        stroke="#0D6EFD"
                        stroke-width="15"
                        width="30"
                        height="30"
                        x="85"
                        y="50"
                    >
                        <animate
                            attributeName="y"
                            calcMode="spline"
                            dur="2"
                            values="50;120;50;"
                            keySplines=".5 0 .5 1;.5 0 .5 1"
                            repeatCount="indefinite"
                            begin="-.2"
                        ></animate>
                    </rect>
                    <rect
                        fill="#0D6EFD"
                        stroke="#0D6EFD"
                        stroke-width="15"
                        width="30"
                        height="30"
                        x="145"
                        y="50"
                    >
                        <animate
                            attributeName="y"
                            calcMode="spline"
                            dur="2"
                            values="50;120;50;"
                            keySplines=".5 0 .5 1;.5 0 .5 1"
                            repeatCount="indefinite"
                            begin="0"
                        ></animate>
                    </rect>
                </svg>
            </div>
        </div>
    );
});
