.logo {
    width: 2rem;
}

.menuButton {
    position: fixed;
    left: 10px;
    bottom: 10px;
    padding: 10px;
    z-index: 5000;
}

.backButton {
    position: fixed;
    right: 10px;
    bottom: 10px;
    padding: 10px;
    z-index: 5000;
}
