import { observer } from 'mobx-react';
import 'react-datepicker/dist/react-datepicker.css';

import {
    OneTimeTaskModel,
    TaskModel
} from '@assets/model/stores/Data/TaskModel/TaskModel';

export const OneTimeTask = observer((props: { task: TaskModel }) => {
    const { task } = props;
    const specific = task.getSpecific<OneTimeTaskModel>();

    const setStartDate = (date: Date): void => {
        specific.date = date;
    };

    return (
        <>
            {' '}
            <div></div>
            {/* <DatePicker
                selected={specific.date}
                onChange={(d: Date) => {
                    setStartDate(d);
                }}
            /> */}
        </>
    );
});
