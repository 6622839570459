import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import {
    getStorage,
    listAll,
    ref,
    uploadBytes,
    getDownloadURL
} from 'firebase/storage';
import { getFirebase } from '@assets/firebase/FirebaseAdapater';
import { ToggleButtonUI } from '@assets/component/ToggleButton/ToggleButton';
import { HTTPClient } from 'koajax';
import { PreviewFetcher } from '@assets/model/stores/Data/Lists/PreviewFetcher';
import { AudioRecorder } from '@assets/component/AudioRecorder/AudioRecorder';
import { StarRating } from '@assets/component/StarRating/StarRating';

export const IconsPage = observer(() => {
    return <StarRating rating={3} onChange={n => {}} />;
});
