import { AudioRecorder } from '@assets/component/AudioRecorder/AudioRecorder';
import { StarRating } from '@assets/component/StarRating/StarRating';
import { useLists } from '@assets/model/Store';
import { useNavUtils } from '@assets/utils/NavUtils';
import { observer } from 'mobx-react';
import moment from 'moment';
import { useEffect } from 'react';
import { Button, Card, Col, Container, Form, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import * as style from './ListsContent.module.less';
const { Dropzone } = require('dropzone');

export const ListsContent = observer(() => {
    const lists = useLists();
    const { id } = useParams();
    const navUtils = useNavUtils();

    useEffect(() => {
        const content = lists.contentList.find(l => l.id === id);
        if (content && !content.thumbnailURL) {
            content.tryToEnhance();
            content.save();
        }
    }, [id]);

    const onDelete = async () => {
        const content = lists.contentList.find(l => l.id === id);
        var p = confirm('Are you sure you want to delete this content?');
        if (p && content) {
            await content.delete();
            navUtils.toLists();
        }
    };

    const content = lists.contentList.find(l => l.id === id);

    var html = <div></div>;

    if (content) {
        const img = content.thumbnailURL ? (
            <img src={content.thumbnailURL} className={style.preview} />
        ) : null;

        const url = content.url ? (
            <a href={content.url} target="_blank">
                Link
            </a>
        ) : null;
        const genres = content.genres.map(g => <div key={g}>{g}</div>);
        const formatDate = (date: Date | null) => {
            if (!date) return '-';
            return moment(date).format('D/MM/YYYY');
        };
        html = (
            <Container>
                <Row className={style.title}>
                    <Col>{content.name}</Col>
                </Row>
                <Row>
                    <Col className={style.overview}>{content.overview}</Col>
                </Row>
                <Row>
                    <Col>
                        <StarRating
                            rating={content.user_rating}
                            onChange={rating => {
                                content.user_rating = rating;
                                content.user_dating_date = new Date();

                                content.save();
                                //Move to auto save
                            }}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col xs={6}>{img}</Col>
                    <Col xs={6}>
                        <Container>
                            <Row>
                                <Col>Genres:</Col>
                                <Col>{genres}</Col>
                            </Row>
                            <Row>
                                <Col>Release:</Col>
                                <Col>{formatDate(content.releaseDate)}</Col>
                            </Row>
                            <Row>
                                <Col>Added:</Col>
                                <Col>{formatDate(content.created)}</Col>
                            </Row>
                            <Row>
                                <Col>URL:</Col>
                                <Col>{url}</Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Control
                                        as="textarea"
                                        rows={4}
                                        wrap="hard"
                                        placeholder="Your thoughts?"
                                        value={content.user_notes}
                                        onChange={e => {
                                            content.user_notes = e.target.value;
                                            content.save();
                                        }}
                                    />

                                    <AudioRecorder
                                        onTranscript={text => {
                                            content.user_notes = text;
                                            content.save();
                                        }}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Button
                                        onClick={() => {
                                            content.tryToEnhance();
                                        }}
                                    >
                                        Enhance
                                    </Button>
                                    <Button onClick={onDelete}>Delete</Button>
                                </Col>
                            </Row>
                        </Container>
                    </Col>
                </Row>
            </Container>
        );
    } else {
        html = <div>Content not found</div>;
    }

    return (
        <Card>
            <Card.Body className="p-1 pt-3">{html}</Card.Body>.
        </Card>
    );
});
