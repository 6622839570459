.taskInfo {
    margin-top: 20px;
    margin-left: 5px;
    height: 160px;
    background-color: #fff;
    width: 96%;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.3);
    font-size: 40px;
    background-image: linear-gradient(
        to bottom,
        #fff calc(1em - 1px),
        #ccc calc(1em - 1px),
        #ccc 1em,
        #fff 1em
    );

    background-position: 0% 1em;
    background-size: 100% 1em;
    background-repeat: repeat-y;
}

.taskInfoTextaArea {
    overflow: hidden;
    height: 100%;
    background-color: transparent !important;
    font-size: 24px !important;
    line-height: 41px !important;
    margin: 0 !important;
    margin-left: 10px !important;
    padding: 0 !important;
    resize: none;
    outline: none !important;
    border: none !important;

    &:focus {
        outline: none !important;
        border: none !important;
        border-color: transparent !important;
        background-color: transparent !important;
        box-shadow: none !important;
    }
}
.attachmentBox {
    height: 64px;
}
.taskIcon {
    border-radius: var(--bs-border-radius);
    width: 64px;
    height: 64px;
    padding-left: 8px;
    padding-right: 12px;
    border: var(--bs-border-width) solid var(--bs-border-color);
}

.uploadIcon {
    width: 64px;
    height: 64px;
    pointer-events: none;
}

.taskPageDropZone {
    margin: 0 !important;
    padding: 0 !important;
    width: 64px;
    height: 64px;
    border-radius: var(--bs-border-radius) !important;
    border: var(--bs-border-width) solid var(--bs-border-color) !important;
    &:hover {
        filter: drop-shadow(0 0 1px var(--bs-primary));
    }
}

.taskName {
    font-size: 20px !important;
    font-weight: bold !important;
}

.attachedFilesList {
    display: flex;
    flex-wrap: wrap;
    border-radius: var(--bs-border-radius) !important;
    border: var(--bs-border-width) solid var(--bs-border-color) !important;
}

.attachedFile {
    width: 64px;
    height: 64px;
    border-radius: var(--bs-border-radius);
    border: var(--bs-border-width) solid var(--bs-border-color);
    margin: 0px;
    justify-content: center;
    align-items: center;
    img {
        width: 100%;
        height: 100%;
    }
}
