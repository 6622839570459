import { observer } from 'mobx-react';
import * as style from './UploadFile.module.less';
import { useEffect, useRef } from 'react';
import { useAssets } from '@assets/model/Store';
import { Icon } from '../Icon/Icon';
const { Dropzone } = require('dropzone');

interface UploadFileProps {
    onNewFile: (file: File) => void;
    className?: string;
}

export const UploadFile = observer((props: UploadFileProps) => {
    const dropzoneRef = useRef(null);
    const assets = useAssets();

    useEffect(() => {
        let myDropzone = new Dropzone(dropzoneRef.current, {
            dictDefaultMessage: '',
            maxFilesize: 2, // MB
            url: '/',
            addRemoveLinks: false,
            method: 'put',
            chunking: true,
            forceChunking: true,
            autoQueue: false,
            autoProcessQueue: false,
            disablePreviews: true
        });

        myDropzone.on('addedfile', async function (file) {
            myDropzone.removeFile(file);
            myDropzone.emit('complete', file);
            props.onNewFile(file);
            setTimeout(() => {
                myDropzone.removeFile(file);
            }, 250);
        });

        return () => {
            while (myDropzone.files.length > 0) {
                myDropzone.removeFile(myDropzone.files[0]);
            }

            myDropzone.destroy();
        };
    }, [dropzoneRef]);

    return (
        <div
            id="taskPageDropZone"
            className={style.taskPageDropZone + ' ' + props.className}
            ref={dropzoneRef}
        >
            <Icon
                iconSys="upload"
                size="M"
                onClick={() => {
                    dropzoneRef.current.click();
                }}
            ></Icon>
        </div>
    );
});
