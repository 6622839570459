import { observer } from 'mobx-react';
import * as style from './ListEntry.module.less';

import { ListContentEntry } from '@assets/model/stores/Data/Lists/ListsStore';
import { useNavUtils } from '@assets/utils/NavUtils';
import { useAssets } from '@assets/model/Store';
import { Icon } from '@assets/component/Icon/Icon';

export const ListEntry = observer(({ entry }: { entry: ListContentEntry }) => {
    const nav = useNavUtils();
    const assets = useAssets();
    const i = entry;
    const icon = assets.getContentIconUrl(i.category);

    var rating = i.user_rating;
    var stars = [];
    for (let i = 1; i <= rating; i++) {
        stars.push(<Icon key={i} iconSys="star" size="XS" />);
    }

    return (
        <div
            key={i.id}
            className={style.contentItem}
            onClick={() => {
                nav.toContent(i.id);
            }}
        >
            {i.thumbnailURL && <img className="thumb" src={i.thumbnailURL} />}
            {i.name}
            <img className="icon" src={icon} />
            <div className={style.starContainer}>{stars}</div>
        </div>
    );
});
